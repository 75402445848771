export function katakanaToHiragana(str: string): string
{
  // In unicode, katakana is from 12449 to 12533, hiragana from 12353, 12435

  return str
    .split("")
    .map((c) =>
    {
      const codePoint = c.codePointAt(0)
      if (codePoint && codePoint >= 12449 && codePoint <= 12534)
        return String.fromCodePoint(codePoint - 96)
      else
        return c
    })
    .join("")
}

export const kanaToRomajiMap: { [kana: string]: string } = {
  ゃ: 'ya',
  ゅ: 'yu',
  ょ: 'yo',
  き: "ki",
  ち: "chi",
  つ: "tsu",
  づ: "zu",
  か: "ka",
//  き: "ki",
  く: "ku",
  け: "ke",
  こ: "ko",
  さ: "sa",
  し: "si",
  す: "su",
  せ: "se",
  そ: "so",
  た: "ta",
//  ち: "ti",
  て: "te",
  と: "to",
  な: "na",
  に: "ni",
  ぬ: "nu",
  ね: "ne",
  の: "no",
  は: "ha",
  ひ: "hi",
//  ふ: "hu",
  ふ: "fu",
  へ: "he",
  ほ: "ho",
  ま: "ma",
  み: "mi",
  む: "mu",
  め: "me",
  も: "mo",
  り: "ri",
  ら: "ra",
  や: "ya",
  ゆ: "yu",
  よ: "yo",
  る: "ru",
  れ: "re",
  ろ: "ro",
  わ: "wa",
  ゐ: "wi",
  ゑ: "we",
  を: "wo",
  が: "ga",
  ぎ: "gi",
  ぐ: "gu",
  げ: "ge",
  ご: "go",
  ざ: "za",
  じ: "ji",
  ず: "zu",
  ぜ: "ze",
  ぞ: "zo",
  だ: "da",
  ぢ: "ji",
//  づ: "du",
  で: "de",
  ど: "do",
  ば: "ba",
  び: "bi",
  ぶ: "bu",
  べ: "be",
  ぼ: "bo",
  ぱ: "pa",
  ぴ: "pi",
  ぷ: "pu",
  ぺ: "pe",
  ぽ: "po",
//  ん: "nn",
  ん: "n",
  あ: "a",
  い: "i",
  う: "u",
  え: "e",
  お: "o"}
  
export const kanjiToKanaMap: { [kanji: string]: string } = {
  亜: 'ア',
  唖: 'ア',
  娃: 'ア',
  阿: 'ア',
  哀: 'アイ',
  愛: 'アイ',
  挨: 'アイ',
  姶: 'オウ',
  逢: 'ホウ',
  葵: 'キ',
  茜: 'セン',
  穐: 'シュウ',
  悪: 'アク',
  握: 'アク',
  渥: 'アク',
  旭: 'キョク',
  葦: 'イ',
  芦: 'ロ',
  鯵: 'ソウ',
  梓: 'シ',
  圧: 'アツ',
  斡: 'アツ',
  扱: 'ソウ',
  宛: 'エン',
  姐: 'ソ',
  虻: 'ボウ',
  飴: 'イ',
  絢: 'ケン',
  綾: 'リン',
  鮎: 'デン',
  或: 'ワク',
  粟: 'ゾク',
  袷: 'コウ',
  安: 'アン',
  庵: 'アン',
  按: 'アン',
  暗: 'アン',
  案: 'アン',
  闇: 'アン',
  鞍: 'アン',
  杏: 'キョウ',
  以: 'イ',
  伊: 'イ',
  位: 'イ',
  依: 'イ',
  偉: 'イ',
  囲: 'イ',
  夷: 'イ',
  委: 'イ',
  威: 'イ',
  尉: 'イ',
  惟: 'イ',
  意: 'イ',
  慰: 'イ',
  易: 'エキ',
  椅: 'イ',
  為: 'イ',
  畏: 'イ',
  異: 'イ',
  移: 'イ',
  維: 'イ',
  緯: 'イ',
  胃: 'イ',
  萎: 'イ',
  衣: 'イ',
  謂: 'イ',
  違: 'イ',
  遺: 'イ',
  医: 'イ',
  井: 'セイ',
  亥: 'ガイ',
  域: 'イキ',
  育: 'イク',
  郁: 'イク',
  磯: 'キ',
  一: 'イチ',
  壱: 'イチ',
  溢: 'イツ',
  逸: 'イツ',
  稲: 'トウ',
  茨: 'シ',
  芋: 'ウ',
  鰯: 'いわし',
  允: 'イン',
  印: 'イン',
  咽: 'イン',
  員: 'イン',
  因: 'イン',
  姻: 'イン',
  引: 'イン',
  飲: 'イン',
  淫: 'イン',
  胤: 'イン',
  蔭: 'イン',
  院: 'イン',
  陰: 'イン',
  隠: 'イン',
  韻: 'イン',
  吋: 'トウ',
  右: 'ウ',
  宇: 'ウ',
  烏: 'ウ',
  羽: 'ウ',
  迂: 'ウ',
  雨: 'ウ',
  卯: 'ボウ',
  鵜: 'テイ',
  窺: 'キ',
  丑: 'チュウ',
  碓: 'カク',
  臼: 'キュウ',
  渦: 'カ',
  嘘: 'キョ',
  唄: 'バイ',
  欝: 'ウツ',
  蔚: 'ウツ',
  鰻: 'マン',
  姥: 'ボ',
  厩: 'キュウ',
  浦: 'ホ',
  瓜: 'カ',
  閏: 'ジュン',
  噂: 'ソン',
  云: 'ウン',
  運: 'ウン',
  雲: 'ウン',
  荏: 'ジン',
  餌: 'ジ',
  叡: 'エイ',
  営: 'エイ',
  嬰: 'エイ',
  影: 'エイ',
  映: 'エイ',
  曳: 'エイ',
  栄: 'エイ',
  永: 'エイ',
  泳: 'エイ',
  洩: 'エイ',
  瑛: 'エイ',
  盈: 'エイ',
  穎: 'エイ',
  頴: 'エイ',
  英: 'エイ',
  衛: 'エイ',
  詠: 'エイ',
  鋭: 'エイ',
  液: 'エキ',
  疫: 'エキ',
  益: 'エキ',
  駅: 'エキ',
  悦: 'エツ',
  謁: 'エツ',
  越: 'エツ',
  閲: 'エツ',
  榎: 'カ',
  厭: 'エン',
  円: 'エン',
  園: 'エン',
  堰: 'エン',
  奄: 'エン',
  宴: 'エン',
  延: 'エン',
  怨: 'エン',
  掩: 'エン',
  援: 'エン',
  沿: 'エン',
  演: 'エン',
  炎: 'エン',
  焔: 'エン',
  煙: 'エン',
  燕: 'エン',
  猿: 'エン',
  縁: 'エン',
  艶: 'エン',
  苑: 'エン',
  薗: 'エン',
  遠: 'エン',
  鉛: 'エン',
  鴛: 'エン',
  塩: 'エン',
  於: 'オ',
  汚: 'オ',
  甥: 'セイ',
  凹: 'オウ',
  央: 'オウ',
  奥: 'オウ',
  往: 'オウ',
  応: 'オウ',
  押: 'オウ',
  旺: 'オウ',
  横: 'オウ',
  欧: 'オウ',
  殴: 'オウ',
  王: 'オウ',
  翁: 'オウ',
  襖: 'オウ',
  鴬: 'オウ',
  鴎: 'オウ',
  黄: 'コウ',
  岡: 'コウ',
  沖: 'チュウ',
  荻: 'テキ',
  億: 'オク',
  屋: 'オク',
  憶: 'オク',
  臆: 'オク',
  桶: 'ヨウ',
  牡: 'ボ',
  乙: 'オツ',
  俺: 'エン',
  卸: 'シャ',
  恩: 'オン',
  温: 'オン',
  穏: 'オン',
  音: 'オン',
  下: 'カ',
  化: 'カ',
  仮: 'カ',
  何: 'カ',
  伽: 'カ',
  価: 'カ',
  佳: 'カ',
  加: 'カ',
  可: 'カ',
  嘉: 'カ',
  夏: 'カ',
  嫁: 'カ',
  家: 'カ',
  寡: 'カ',
  科: 'カ',
  暇: 'カ',
  果: 'カ',
  架: 'カ',
  歌: 'カ',
  河: 'カ',
  火: 'カ',
  珂: 'カ',
  禍: 'カ',
  禾: 'カ',
  稼: 'カ',
  箇: 'カ',
  花: 'カ',
  苛: 'カ',
  茄: 'カ',
  荷: 'カ',
  華: 'カ',
  菓: 'カ',
  蝦: 'カ',
  課: 'カ',
  嘩: 'カ',
  貨: 'カ',
  迦: 'カ',
  過: 'カ',
  霞: 'カ',
  蚊: 'ブン',
  俄: 'ガ',
  峨: 'ガ',
  我: 'ガ',
  牙: 'ガ',
  画: 'ガ',
  臥: 'ガ',
  芽: 'ガ',
  蛾: 'ギ',
  賀: 'ガ',
  雅: 'ガ',
  餓: 'ガ',
  駕: 'カ',
  介: 'カイ',
  会: 'カイ',
  解: 'カイ',
  回: 'カイ',
  塊: 'カイ',
  壊: 'カイ',
  廻: 'カイ',
  快: 'カイ',
  怪: 'カイ',
  悔: 'カイ',
  恢: 'カイ',
  懐: 'カイ',
  戒: 'カイ',
  拐: 'カイ',
  改: 'カイ',
  魁: 'カイ',
  晦: 'カイ',
  械: 'カイ',
  海: 'カイ',
  灰: 'カイ',
  界: 'カイ',
  皆: 'カイ',
  絵: 'カイ',
  芥: 'カイ',
  蟹: 'カイ',
  開: 'カイ',
  階: 'カイ',
  貝: 'バイ',
  凱: 'ガイ',
  劾: 'ガイ',
  外: 'ガイ',
  咳: 'カイ',
  害: 'ガイ',
  崖: 'ガイ',
  慨: 'ガイ',
  概: 'ガイ',
  涯: 'ガイ',
  碍: 'ガイ',
  蓋: 'ガイ',
  街: 'ガイ',
  該: 'ガイ',
  鎧: 'カイ',
  骸: 'ガイ',
  浬: 'リ',
  馨: 'ケイ',
  蛙: 'ア',
  垣: 'エン',
  柿: 'シ',
  蛎: 'レイ',
  鈎: 'コウ',
  劃: 'カク',
  嚇: 'カク',
  各: 'カク',
  廓: 'カク',
  拡: 'カク',
  撹: 'カク',
  格: 'カク',
  核: 'カク',
  殻: 'カク',
  獲: 'カク',
  確: 'カク',
  穫: 'カク',
  覚: 'カク',
  角: 'カク',
  赫: 'カク',
  較: 'カク',
  郭: 'カク',
  閣: 'カク',
  隔: 'カク',
  革: 'カク',
  学: 'ガク',
  岳: 'ガク',
  楽: 'ガク',
  額: 'ガク',
  顎: 'ガク',
  掛: 'カイ',
  笠: 'リュウ',
  樫: 'かし',
  橿: 'キョウ',
  梶: 'ビ',
  鰍: 'シュウ',
  潟: 'セキ',
  割: 'カツ',
  喝: 'カツ',
  恰: 'コウ',
  括: 'カツ',
  活: 'カツ',
  渇: 'カツ',
  滑: 'カツ',
  葛: 'カツ',
  褐: 'カツ',
  轄: 'カツ',
  且: 'ショ',
  鰹: 'ケン',
  叶: 'キョウ',
  椛: 'かば',
  樺: 'カ',
  鞄: 'ハク',
  株: 'シュ',
  兜: 'トウ',
  竃: 'ソウ',
  蒲: 'ホ',
  釜: 'フ',
  鎌: 'レン',
  噛: 'コウ',
  鴨: 'オウ',
  栢: 'ハク',
  茅: 'ボウ',
  萱: 'ケン',
  粥: 'イク',
  刈: 'ガイ',
  苅: 'ガイ',
  瓦: 'ガ',
  乾: 'カン',
  侃: 'カン',
  冠: 'カン',
  寒: 'カン',
  刊: 'カン',
  勘: 'カン',
  勧: 'カン',
  巻: 'カン',
  喚: 'カン',
  堪: 'カン',
  姦: 'カン',
  完: 'カン',
  官: 'カン',
  寛: 'カン',
  干: 'カン',
  幹: 'カン',
  患: 'カン',
  感: 'カン',
  慣: 'カン',
  憾: 'カン',
  換: 'カン',
  敢: 'カン',
  柑: 'コン',
  桓: 'カン',
  棺: 'カン',
  款: 'カン',
  歓: 'カン',
  汗: 'カン',
  漢: 'カン',
  澗: 'カン',
  潅: 'カン',
  環: 'カン',
  甘: 'カン',
  監: 'カン',
  看: 'カン',
  竿: 'カン',
  管: 'カン',
  簡: 'カン',
  緩: 'カン',
  缶: 'カン',
  翰: 'カン',
  肝: 'カン',
  艦: 'カン',
  莞: 'カン',
  観: 'カン',
  諌: 'カン',
  貫: 'カン',
  還: 'カン',
  鑑: 'カン',
  間: 'カン',
  閑: 'カン',
  関: 'カン',
  陥: 'カン',
  韓: 'カン',
  館: 'カン',
  舘: 'カン',
  丸: 'ガン',
  含: 'ガン',
  岸: 'ガン',
  巌: 'ガン',
  玩: 'ガン',
  癌: 'ガン',
  眼: 'ガン',
  岩: 'ガン',
  翫: 'ガン',
  贋: 'ガン',
  雁: 'ガン',
  頑: 'ガン',
  顔: 'ガン',
  願: 'ガン',
  企: 'キ',
  伎: 'ギ',
  危: 'キ',
  喜: 'キ',
  器: 'キ',
  基: 'キ',
  奇: 'キ',
  嬉: 'キ',
  寄: 'キ',
  岐: 'キ',
  希: 'キ',
  幾: 'キ',
  忌: 'キ',
  揮: 'キ',
  机: 'キ',
  旗: 'キ',
  既: 'キ',
  期: 'キ',
  棋: 'キ',
  棄: 'キ',
  機: 'キ',
  帰: 'キ',
  毅: 'キ',
  気: 'キ',
  汽: 'キ',
  畿: 'キ',
  祈: 'キ',
  季: 'キ',
  稀: 'キ',
  紀: 'キ',
  徽: 'キ',
  規: 'キ',
  記: 'キ',
  貴: 'キ',
  起: 'キ',
  軌: 'キ',
  輝: 'キ',
  飢: 'キ',
  騎: 'キ',
  鬼: 'キ',
  亀: 'キ',
  偽: 'ギ',
  儀: 'ギ',
  妓: 'ギ',
  宜: 'ギ',
  戯: 'ギ',
  技: 'ギ',
  擬: 'ギ',
  欺: 'ギ',
  犠: 'ギ',
  疑: 'ギ',
  祇: 'ギ',
  義: 'ギ',
  蟻: 'ギ',
  誼: 'ギ',
  議: 'ギ',
  掬: 'キク',
  菊: 'キク',
  鞠: 'キク',
  吉: 'キチ',
  吃: 'キツ',
  喫: 'キツ',
  桔: 'キツ',
  橘: 'キツ',
  詰: 'キツ',
  砧: 'チン',
  杵: 'ショ',
  黍: 'ショ',
  却: 'キャク',
  客: 'キャク',
  脚: 'キャク',
  虐: 'ギャク',
  逆: 'ギャク',
  丘: 'キュウ',
  久: 'キュウ',
  仇: 'キュウ',
  休: 'キュウ',
  及: 'キュウ',
  吸: 'キュウ',
  宮: 'キュウ',
  弓: 'キュウ',
  急: 'キュウ',
  救: 'キュウ',
  朽: 'キュウ',
  求: 'キュウ',
  汲: 'キュウ',
  泣: 'キュウ',
  灸: 'キュウ',
  球: 'キュウ',
  究: 'キュウ',
  窮: 'キュウ',
  笈: 'キュウ',
  級: 'キュウ',
  糾: 'キュウ',
  給: 'キュウ',
  旧: 'キュウ',
  牛: 'ギュウ',
  去: 'キョ',
  居: 'キョ',
  巨: 'キョ',
  拒: 'キョ',
  拠: 'キョ',
  挙: 'キョ',
  渠: 'キョ',
  虚: 'キョ',
  許: 'キョ',
  距: 'キョ',
  鋸: 'キョ',
  漁: 'ギョ',
  禦: 'ギョ',
  魚: 'ギョ',
  亨: 'コウ',
  享: 'キョウ',
  京: 'キョウ',
  供: 'キョウ',
  侠: 'キョウ',
  僑: 'キョウ',
  兇: 'キョウ',
  競: 'キョウ',
  共: 'キョウ',
  凶: 'キョウ',
  協: 'キョウ',
  匡: 'キョウ',
  卿: 'ケイ',
  叫: 'キョウ',
  喬: 'キョウ',
  境: 'キョウ',
  峡: 'キョウ',
  強: 'キョウ',
  彊: 'キョウ',
  怯: 'キョウ',
  恐: 'キョウ',
  恭: 'キョウ',
  挟: 'キョウ',
  教: 'キョウ',
  橋: 'キョウ',
  況: 'キョウ',
  狂: 'キョウ',
  狭: 'キョウ',
  矯: 'キョウ',
  胸: 'キョウ',
  脅: 'キョウ',
  興: 'コウ',
  蕎: 'キョウ',
  郷: 'キョウ',
  鏡: 'キョウ',
  響: 'キョウ',
  饗: 'キョウ',
  驚: 'キョウ',
  仰: 'ギョウ',
  凝: 'ギョウ',
  尭: 'ギョウ',
  暁: 'ギョウ',
  業: 'ギョウ',
  局: 'キョク',
  曲: 'キョク',
  極: 'キョク',
  玉: 'ギョク',
  桐: 'トウ',
  粁: 'キロメートル',
  僅: 'キン',
  勤: 'キン',
  均: 'キン',
  巾: 'キン',
  錦: 'キン',
  斤: 'キン',
  欣: 'キン',
  欽: 'キン',
  琴: 'キン',
  禁: 'キン',
  禽: 'キン',
  筋: 'キン',
  緊: 'キン',
  芹: 'キン',
  菌: 'キン',
  衿: 'キン',
  襟: 'キン',
  謹: 'キン',
  近: 'キン',
  金: 'キン',
  吟: 'ギン',
  銀: 'ギン',
  九: 'キュウ',
  倶: 'グ',
  句: 'ク',
  区: 'ク',
  狗: 'ク',
  玖: 'キュウ',
  矩: 'ク',
  苦: 'ク',
  躯: 'ク',
  駆: 'ク',
  駈: 'ク',
  駒: 'ク',
  具: 'グ',
  愚: 'グ',
  虞: 'グ',
  喰: 'く.う',
  空: 'クウ',
  偶: 'グウ',
  寓: 'グウ',
  遇: 'グウ',
  隅: 'グウ',
  串: 'カン',
  櫛: 'シツ',
  釧: 'セン',
  屑: 'セツ',
  屈: 'クツ',
  掘: 'クツ',
  窟: 'クツ',
  沓: 'トウ',
  靴: 'カ',
  轡: 'ヒ',
  窪: 'ワ',
  熊: 'ユウ',
  隈: 'ワイ',
  粂: 'くめ',
  栗: 'リツ',
  繰: 'ソウ',
  桑: 'ソウ',
  鍬: 'ショウ',
  勲: 'クン',
  君: 'クン',
  薫: 'クン',
  訓: 'クン',
  群: 'グン',
  軍: 'グン',
  郡: 'グン',
  卦: 'カ',
  袈: 'ケ',
  祁: 'キ',
  係: 'ケイ',
  傾: 'ケイ',
  刑: 'ケイ',
  兄: 'ケイ',
  啓: 'ケイ',
  圭: 'ケイ',
  珪: 'ケイ',
  型: 'ケイ',
  契: 'ケイ',
  形: 'ケイ',
  径: 'ケイ',
  恵: 'ケイ',
  慶: 'ケイ',
  慧: 'ケイ',
  憩: 'ケイ',
  掲: 'ケイ',
  携: 'ケイ',
  敬: 'ケイ',
  景: 'ケイ',
  桂: 'ケイ',
  渓: 'ケイ',
  畦: 'ケイ',
  稽: 'ケイ',
  系: 'ケイ',
  経: 'ケイ',
  継: 'ケイ',
  繋: 'ケイ',
  罫: 'ケイ',
  茎: 'ケイ',
  荊: 'ケイ',
  蛍: 'ケイ',
  計: 'ケイ',
  詣: 'ケイ',
  警: 'ケイ',
  軽: 'ケイ',
  頚: 'ケイ',
  鶏: 'ケイ',
  芸: 'ゲイ',
  迎: 'ゲイ',
  鯨: 'ゲイ',
  劇: 'ゲキ',
  戟: 'ゲキ',
  撃: 'ゲキ',
  激: 'ゲキ',
  隙: 'ゲキ',
  桁: 'コウ',
  傑: 'ケツ',
  欠: 'ケツ',
  決: 'ケツ',
  潔: 'ケツ',
  穴: 'ケツ',
  結: 'ケツ',
  血: 'ケツ',
  訣: 'ケツ',
  月: 'ゲツ',
  件: 'ケン',
  倹: 'ケン',
  倦: 'ケン',
  健: 'ケン',
  兼: 'ケン',
  券: 'ケン',
  剣: 'ケン',
  喧: 'ケン',
  圏: 'ケン',
  堅: 'ケン',
  嫌: 'ケン',
  建: 'ケン',
  憲: 'ケン',
  懸: 'ケン',
  拳: 'ケン',
  捲: 'ケン',
  検: 'ケン',
  権: 'ケン',
  牽: 'ケン',
  犬: 'ケン',
  献: 'ケン',
  研: 'ケン',
  硯: 'ケン',
  絹: 'ケン',
  県: 'ケン',
  肩: 'ケン',
  見: 'ケン',
  謙: 'ケン',
  賢: 'ケン',
  軒: 'ケン',
  遣: 'ケン',
  鍵: 'ケン',
  険: 'ケン',
  顕: 'ケン',
  験: 'ケン',
  鹸: 'ケン',
  元: 'ゲン',
  原: 'ゲン',
  厳: 'ゲン',
  幻: 'ゲン',
  弦: 'ゲン',
  減: 'ゲン',
  源: 'ゲン',
  玄: 'ゲン',
  現: 'ゲン',
  絃: 'ゲン',
  舷: 'ゲン',
  言: 'ゲン',
  諺: 'ゲン',
  限: 'ゲン',
  乎: 'コ',
  個: 'コ',
  古: 'コ',
  呼: 'コ',
  固: 'コ',
  姑: 'コ',
  孤: 'コ',
  己: 'コ',
  庫: 'コ',
  弧: 'コ',
  戸: 'コ',
  故: 'コ',
  枯: 'コ',
  湖: 'コ',
  狐: 'コ',
  糊: 'コ',
  袴: 'コ',
  股: 'コ',
  胡: 'ウ',
  菰: 'コ',
  虎: 'コ',
  誇: 'コ',
  跨: 'コ',
  鈷: 'コ',
  雇: 'コ',
  顧: 'コ',
  鼓: 'コ',
  五: 'ゴ',
  互: 'ゴ',
  伍: 'ゴ',
  午: 'ゴ',
  呉: 'ゴ',
  吾: 'ゴ',
  娯: 'ゴ',
  後: 'ゴ',
  御: 'ギョ',
  悟: 'ゴ',
  梧: 'ゴ',
  檎: 'キン',
  瑚: 'コ',
  碁: 'ゴ',
  語: 'ゴ',
  誤: 'ゴ',
  護: 'ゴ',
  醐: 'ゴ',
  乞: 'コツ',
  鯉: 'リ',
  交: 'コウ',
  佼: 'キョウ',
  侯: 'コウ',
  候: 'コウ',
  倖: 'コウ',
  光: 'コウ',
  公: 'コウ',
  功: 'コウ',
  効: 'コウ',
  勾: 'コウ',
  厚: 'コウ',
  口: 'コウ',
  向: 'コウ',
  后: 'コウ',
  喉: 'コウ',
  坑: 'コウ',
  垢: 'コウ',
  好: 'コウ',
  孔: 'コウ',
  孝: 'コウ',
  宏: 'コウ',
  工: 'コウ',
  巧: 'コウ',
  巷: 'コウ',
  幸: 'コウ',
  広: 'コウ',
  庚: 'コウ',
  康: 'コウ',
  弘: 'コウ',
  恒: 'コウ',
  慌: 'コウ',
  抗: 'コウ',
  拘: 'コウ',
  控: 'コウ',
  攻: 'コウ',
  昂: 'コウ',
  晃: 'コウ',
  更: 'コウ',
  杭: 'コウ',
  校: 'コウ',
  梗: 'コウ',
  構: 'コウ',
  江: 'コウ',
  洪: 'コウ',
  浩: 'コウ',
  港: 'コウ',
  溝: 'コウ',
  甲: 'コウ',
  皇: 'コウ',
  硬: 'コウ',
  稿: 'コウ',
  糠: 'コウ',
  紅: 'コウ',
  紘: 'コウ',
  絞: 'コウ',
  綱: 'コウ',
  耕: 'コウ',
  考: 'コウ',
  肯: 'コウ',
  肱: 'コウ',
  腔: 'コウ',
  膏: 'コウ',
  航: 'コウ',
  荒: 'コウ',
  行: 'コウ',
  衡: 'コウ',
  講: 'コウ',
  貢: 'コウ',
  購: 'コウ',
  郊: 'コウ',
  酵: 'コウ',
  鉱: 'コウ',
  砿: 'コウ',
  鋼: 'コウ',
  閤: 'コウ',
  降: 'コウ',
  項: 'コウ',
  香: 'コウ',
  高: 'コウ',
  鴻: 'コウ',
  剛: 'ゴウ',
  劫: 'コウ',
  号: 'ゴウ',
  合: 'ゴウ',
  壕: 'コウ',
  拷: 'ゴウ',
  濠: 'ゴウ',
  豪: 'ゴウ',
  轟: 'ゴウ',
  麹: 'キク',
  克: 'コク',
  刻: 'コク',
  告: 'コク',
  国: 'コク',
  穀: 'コク',
  酷: 'コク',
  鵠: 'コク',
  黒: 'コク',
  獄: 'ゴク',
  漉: 'ロク',
  腰: 'ヨウ',
  甑: 'ソウ',
  忽: 'コツ',
  惚: 'コツ',
  骨: 'コツ',
  狛: 'ハク',
  込: 'こ.む',
  此: 'シ',
  頃: 'ケイ',
  今: 'コン',
  困: 'コン',
  坤: 'コン',
  墾: 'コン',
  婚: 'コン',
  恨: 'コン',
  懇: 'コン',
  昏: 'コン',
  昆: 'コン',
  根: 'コン',
  梱: 'コン',
  混: 'コン',
  痕: 'コン',
  紺: 'コン',
  艮: 'コン',
  魂: 'コン',
  些: 'サ',
  佐: 'サ',
  叉: 'サ',
  唆: 'サ',
  嵯: 'サ',
  左: 'サ',
  差: 'サ',
  査: 'サ',
  沙: 'サ',
  瑳: 'サ',
  砂: 'サ',
  詐: 'サ',
  鎖: 'サ',
  裟: 'サ',
  坐: 'ザ',
  座: 'ザ',
  挫: 'ザ',
  債: 'サイ',
  催: 'サイ',
  再: 'サイ',
  最: 'サイ',
  哉: 'サイ',
  塞: 'ソク',
  妻: 'サイ',
  宰: 'サイ',
  彩: 'サイ',
  才: 'サイ',
  採: 'サイ',
  栽: 'サイ',
  歳: 'サイ',
  済: 'サイ',
  災: 'サイ',
  采: 'サイ',
  犀: 'サイ',
  砕: 'サイ',
  砦: 'サイ',
  祭: 'サイ',
  斎: 'サイ',
  細: 'サイ',
  菜: 'サイ',
  裁: 'サイ',
  載: 'サイ',
  際: 'サイ',
  剤: 'ザイ',
  在: 'ザイ',
  材: 'ザイ',
  罪: 'ザイ',
  財: 'ザイ',
  冴: 'ゴ',
  坂: 'ハン',
  阪: 'ハン',
  堺: 'カイ',
  榊: 'さかき',
  肴: 'コウ',
  咲: 'ショウ',
  崎: 'キ',
  埼: 'キ',
  碕: 'キ',
  鷺: 'ロ',
  作: 'サク',
  削: 'サク',
  咋: 'サク',
  搾: 'サク',
  昨: 'サク',
  朔: 'サク',
  柵: 'サク',
  窄: 'サク',
  策: 'サク',
  索: 'サク',
  錯: 'サク',
  桜: 'オウ',
  鮭: 'カイ',
  笹: 'ささ',
  匙: 'シ',
  冊: 'サツ',
  刷: 'サツ',
  察: 'サツ',
  拶: 'サツ',
  撮: 'サツ',
  擦: 'サツ',
  札: 'サツ',
  殺: 'サツ',
  薩: 'サツ',
  雑: 'ザツ',
  皐: 'コウ',
  鯖: 'セイ',
  捌: 'ハツ',
  錆: 'ショウ',
  鮫: 'コウ',
  皿: 'ベイ',
  晒: 'サイ',
  三: 'サン',
  傘: 'サン',
  参: 'サン',
  山: 'サン',
  惨: 'サン',
  撒: 'サン',
  散: 'サン',
  桟: 'サン',
  燦: 'サン',
  珊: 'サン',
  産: 'サン',
  算: 'サン',
  纂: 'サン',
  蚕: 'サン',
  讃: 'サン',
  賛: 'サン',
  酸: 'サン',
  餐: 'サン',
  斬: 'ザン',
  暫: 'ザン',
  残: 'ザン',
  仕: 'シ',
  仔: 'シ',
  伺: 'シ',
  使: 'シ',
  刺: 'シ',
  司: 'シ',
  史: 'シ',
  嗣: 'シ',
  四: 'シ',
  士: 'シ',
  始: 'シ',
  姉: 'シ',
  姿: 'シ',
  子: 'シ',
  屍: 'シ',
  市: 'シ',
  師: 'シ',
  志: 'シ',
  思: 'シ',
  指: 'シ',
  支: 'シ',
  孜: 'シ',
  斯: 'シ',
  施: 'シ',
  旨: 'シ',
  枝: 'シ',
  止: 'シ',
  死: 'シ',
  氏: 'シ',
  獅: 'シ',
  祉: 'シ',
  私: 'シ',
  糸: 'シ',
  紙: 'シ',
  紫: 'シ',
  肢: 'シ',
  脂: 'シ',
  至: 'シ',
  視: 'シ',
  詞: 'シ',
  詩: 'シ',
  試: 'シ',
  誌: 'シ',
  諮: 'シ',
  資: 'シ',
  賜: 'シ',
  雌: 'シ',
  飼: 'シ',
  歯: 'シ',
  事: 'ジ',
  似: 'ジ',
  侍: 'ジ',
  児: 'ジ',
  字: 'ジ',
  寺: 'ジ',
  慈: 'ジ',
  持: 'ジ',
  時: 'ジ',
  次: 'ジ',
  滋: 'ジ',
  治: 'ジ',
  爾: 'ジ',
  璽: 'ジ',
  痔: 'ヂ',
  磁: 'ジ',
  示: 'ジ',
  而: 'ジ',
  耳: 'ジ',
  自: 'ジ',
  蒔: 'シ',
  辞: 'ジ',
  汐: 'セキ',
  鹿: 'ロク',
  式: 'シキ',
  識: 'シキ',
  鴫: 'しぎ',
  竺: 'ジク',
  軸: 'ジク',
  宍: 'ニク',
  雫: 'ダ',
  七: 'シチ',
  叱: 'シツ',
  執: 'シツ',
  失: 'シツ',
  嫉: 'シツ',
  室: 'シツ',
  悉: 'シツ',
  湿: 'シツ',
  漆: 'シツ',
  疾: 'シツ',
  質: 'シツ',
  実: 'ジツ',
  蔀: 'ホウ',
  篠: 'ゾウ',
  偲: 'サイ',
  柴: 'サイ',
  芝: 'シ',
  屡: 'ル',
  蕊: 'ズイ',
  縞: 'コウ',
  舎: 'シャ',
  写: 'シャ',
  射: 'シャ',
  捨: 'シャ',
  赦: 'シャ',
  斜: 'シャ',
  煮: 'シャ',
  社: 'シャ',
  紗: 'サ',
  者: 'シャ',
  謝: 'シャ',
  車: 'シャ',
  遮: 'シャ',
  蛇: 'ジャ',
  邪: 'ジャ',
  借: 'シャク',
  勺: 'シャク',
  尺: 'シャク',
  杓: 'シャク',
  灼: 'シャク',
  爵: 'シャク',
  酌: 'シャク',
  釈: 'シャク',
  錫: 'セキ',
  若: 'ジャク',
  寂: 'ジャク',
  弱: 'ジャク',
  惹: 'ジャク',
  主: 'シュ',
  取: 'シュ',
  守: 'シュ',
  手: 'シュ',
  朱: 'シュ',
  殊: 'シュ',
  狩: 'シュ',
  珠: 'シュ',
  種: 'シュ',
  腫: 'シュ',
  趣: 'シュ',
  酒: 'シュ',
  首: 'シュ',
  儒: 'ジュ',
  受: 'ジュ',
  呪: 'ジュ',
  寿: 'ジュ',
  授: 'ジュ',
  樹: 'ジュ',
  綬: 'ジュ',
  需: 'ジュ',
  囚: 'シュウ',
  収: 'シュウ',
  周: 'シュウ',
  宗: 'シュウ',
  就: 'シュウ',
  州: 'シュウ',
  修: 'シュウ',
  愁: 'シュウ',
  拾: 'シュウ',
  洲: 'シュウ',
  秀: 'シュウ',
  秋: 'シュウ',
  終: 'シュウ',
  繍: 'シュウ',
  習: 'シュウ',
  臭: 'シュウ',
  舟: 'シュウ',
  蒐: 'シュウ',
  衆: 'シュウ',
  襲: 'シュウ',
  讐: 'シュウ',
  蹴: 'シュク',
  輯: 'シュウ',
  週: 'シュウ',
  酋: 'シュウ',
  酬: 'シュウ',
  集: 'シュウ',
  醜: 'シュウ',
  什: 'ジュウ',
  住: 'ジュウ',
  充: 'ジュウ',
  十: 'ジュウ',
  従: 'ジュウ',
  戎: 'ジュウ',
  柔: 'ジュウ',
  汁: 'ジュウ',
  渋: 'ジュウ',
  獣: 'ジュウ',
  縦: 'ジュウ',
  重: 'ジュウ',
  銃: 'ジュウ',
  叔: 'シュク',
  夙: 'シュク',
  宿: 'シュク',
  淑: 'シュク',
  祝: 'シュク',
  縮: 'シュク',
  粛: 'シュク',
  塾: 'ジュク',
  熟: 'ジュク',
  出: 'シュツ',
  術: 'ジュツ',
  述: 'ジュツ',
  俊: 'シュン',
  峻: 'シュン',
  春: 'シュン',
  瞬: 'シュン',
  竣: 'ドウ',
  舜: 'シュン',
  駿: 'シュン',
  准: 'ジュン',
  循: 'ジュン',
  旬: 'ジュン',
  楯: 'ジュン',
  殉: 'ジュン',
  淳: 'ジュン',
  準: 'ジュン',
  潤: 'ジュン',
  盾: 'ジュン',
  純: 'ジュン',
  巡: 'ジュン',
  遵: 'ジュン',
  醇: 'ジュン',
  順: 'ジュン',
  処: 'ショ',
  初: 'ショ',
  所: 'ショ',
  暑: 'ショ',
  曙: 'ショ',
  渚: 'ショ',
  庶: 'ショ',
  緒: 'ショ',
  署: 'ショ',
  書: 'ショ',
  薯: 'ショ',
  藷: 'ショ',
  諸: 'ショ',
  助: 'ジョ',
  叙: 'ジョ',
  女: 'ジョ',
  序: 'ジョ',
  徐: 'ジョ',
  恕: 'ジョ',
  鋤: 'ジョ',
  除: 'ジョ',
  傷: 'ショウ',
  償: 'ショウ',
  勝: 'ショウ',
  匠: 'ショウ',
  升: 'ショウ',
  召: 'ショウ',
  哨: 'ショウ',
  商: 'ショウ',
  唱: 'ショウ',
  嘗: 'ショウ',
  奨: 'ショウ',
  妾: 'ショウ',
  娼: 'ショウ',
  宵: 'ショウ',
  将: 'ショウ',
  小: 'ショウ',
  少: 'ショウ',
  尚: 'ショウ',
  庄: 'ショウ',
  床: 'ショウ',
  廠: 'ショウ',
  彰: 'ショウ',
  承: 'ショウ',
  抄: 'ショウ',
  招: 'ショウ',
  掌: 'ショウ',
  捷: 'ショウ',
  昇: 'ショウ',
  昌: 'ショウ',
  昭: 'ショウ',
  晶: 'ショウ',
  松: 'ショウ',
  梢: 'ショウ',
  樟: 'ショウ',
  樵: 'ショウ',
  沼: 'ショウ',
  消: 'ショウ',
  渉: 'ショウ',
  湘: 'ショウ',
  焼: 'ショウ',
  焦: 'ショウ',
  照: 'ショウ',
  症: 'ショウ',
  省: 'セイ',
  硝: 'ショウ',
  礁: 'ショウ',
  祥: 'ショウ',
  称: 'ショウ',
  章: 'ショウ',
  笑: 'ショウ',
  粧: 'ショウ',
  紹: 'ショウ',
  肖: 'ショウ',
  菖: 'ショウ',
  蒋: 'ショウ',
  蕉: 'ショウ',
  衝: 'ショウ',
  裳: 'ショウ',
  訟: 'ショウ',
  証: 'ショウ',
  詔: 'ショウ',
  詳: 'ショウ',
  象: 'ショウ',
  賞: 'ショウ',
  醤: 'ショウ',
  鉦: 'セイ',
  鍾: 'ショウ',
  鐘: 'ショウ',
  障: 'ショウ',
  鞘: 'ショウ',
  上: 'ジョウ',
  丈: 'ジョウ',
  丞: 'ジョウ',
  乗: 'ジョウ',
  冗: 'ジョウ',
  剰: 'ジョウ',
  城: 'ジョウ',
  場: 'ジョウ',
  壌: 'ジョウ',
  嬢: 'ジョウ',
  常: 'ジョウ',
  情: 'ジョウ',
  擾: 'ジョウ',
  条: 'ジョウ',
  杖: 'ジョウ',
  浄: 'ジョウ',
  状: 'ジョウ',
  畳: 'ジョウ',
  穣: 'ジョウ',
  蒸: 'ジョウ',
  譲: 'ジョウ',
  醸: 'ジョウ',
  錠: 'ジョウ',
  嘱: 'ショク',
  埴: 'ショク',
  飾: 'ショク',
  拭: 'ショク',
  植: 'ショク',
  殖: 'ショク',
  燭: 'ソク',
  織: 'ショク',
  職: 'ショク',
  色: 'ショク',
  触: 'ショク',
  食: 'ショク',
  蝕: 'ショク',
  辱: 'ジョク',
  尻: 'コウ',
  伸: 'シン',
  信: 'シン',
  侵: 'シン',
  唇: 'シン',
  娠: 'シン',
  寝: 'シン',
  審: 'シン',
  心: 'シン',
  慎: 'シン',
  振: 'シン',
  新: 'シン',
  晋: 'シン',
  森: 'シン',
  榛: 'シン',
  浸: 'シン',
  深: 'シン',
  申: 'シン',
  疹: 'シン',
  真: 'シン',
  神: 'シン',
  秦: 'シン',
  紳: 'シン',
  臣: 'シン',
  芯: 'シン',
  薪: 'シン',
  親: 'シン',
  診: 'シン',
  身: 'シン',
  辛: 'シン',
  進: 'シン',
  針: 'シン',
  震: 'シン',
  人: 'ジン',
  仁: 'ジン',
  刃: 'ジン',
  塵: 'チン',
  壬: 'ニン',
  尋: 'ジン',
  甚: 'ジン',
  尽: 'ジン',
  腎: 'ジン',
  訊: 'ジン',
  迅: 'ジン',
  陣: 'ジン',
  靭: 'ジン',
  笥: 'ス',
  諏: 'シュ',
  須: 'ス',
  酢: 'サク',
  図: 'ズ',
  厨: 'シュウ',
  逗: 'トウ',
  吹: 'スイ',
  垂: 'スイ',
  帥: 'スイ',
  推: 'スイ',
  水: 'スイ',
  炊: 'スイ',
  睡: 'スイ',
  粋: 'スイ',
  翠: 'スイ',
  衰: 'スイ',
  遂: 'スイ',
  酔: 'スイ',
  錐: 'スイ',
  錘: 'スイ',
  随: 'ズイ',
  瑞: 'ズイ',
  髄: 'ズイ',
  崇: 'スウ',
  嵩: 'スウ',
  数: 'スウ',
  枢: 'スウ',
  趨: 'スウ',
  雛: 'スウ',
  据: 'キョ',
  杉: 'サン',
  椙: 'すぎ',
  菅: 'カン',
  頗: 'ハ',
  雀: 'ジャク',
  裾: 'キョ',
  澄: 'チョウ',
  摺: 'ショウ',
  寸: 'スン',
  世: 'セイ',
  瀬: 'ライ',
  畝: 'ボウ',
  是: 'ゼ',
  凄: 'セイ',
  制: 'セイ',
  勢: 'セイ',
  姓: 'セイ',
  征: 'セイ',
  性: 'セイ',
  成: 'セイ',
  政: 'セイ',
  整: 'セイ',
  星: 'セイ',
  晴: 'セイ',
  棲: 'セイ',
  栖: 'セイ',
  正: 'セイ',
  清: 'セイ',
  牲: 'セイ',
  生: 'セイ',
  盛: 'セイ',
  精: 'セイ',
  聖: 'セイ',
  声: 'セイ',
  製: 'セイ',
  西: 'セイ',
  誠: 'セイ',
  誓: 'セイ',
  請: 'セイ',
  逝: 'セイ',
  醒: 'セイ',
  青: 'セイ',
  静: 'セイ',
  斉: 'セイ',
  税: 'ゼイ',
  脆: 'ゼイ',
  隻: 'セキ',
  席: 'セキ',
  惜: 'セキ',
  戚: 'ソク',
  斥: 'セキ',
  昔: 'セキ',
  析: 'セキ',
  石: 'セキ',
  積: 'セキ',
  籍: 'セキ',
  績: 'セキ',
  脊: 'セキ',
  責: 'セキ',
  赤: 'セキ',
  跡: 'セキ',
  蹟: 'セキ',
  碩: 'セキ',
  切: 'セツ',
  拙: 'セツ',
  接: 'セツ',
  摂: 'セツ',
  折: 'セツ',
  設: 'セツ',
  窃: 'セツ',
  節: 'セツ',
  説: 'セツ',
  雪: 'セツ',
  絶: 'ゼツ',
  舌: 'ゼツ',
  蝉: 'セン',
  仙: 'セン',
  先: 'セン',
  千: 'セン',
  占: 'セン',
  宣: 'セン',
  専: 'セン',
  尖: 'セン',
  川: 'セン',
  戦: 'セン',
  扇: 'セン',
  撰: 'サン',
  栓: 'セン',
  栴: 'セイ',
  泉: 'セン',
  浅: 'セン',
  洗: 'セン',
  染: 'セン',
  潜: 'セン',
  煎: 'セン',
  煽: 'セン',
  旋: 'セン',
  穿: 'セン',
  箭: 'セン',
  線: 'セン',
  繊: 'セン',
  羨: 'セン',
  腺: 'セン',
  舛: 'ブ',
  船: 'セン',
  薦: 'セン',
  詮: 'セン',
  賎: 'セン',
  践: 'セン',
  選: 'セン',
  遷: 'セン',
  銭: 'セン',
  銑: 'セン',
  閃: 'セン',
  鮮: 'セン',
  前: 'ゼン',
  善: 'ゼン',
  漸: 'ゼン',
  然: 'ゼン',
  全: 'ゼン',
  禅: 'ゼン',
  繕: 'ゼン',
  膳: 'ゼン',
  糎: 'センチ',
  噌: 'ソウ',
  塑: 'ソ',
  岨: 'ソ',
  措: 'ソ',
  曾: 'ソウ',
  曽: 'ソウ',
  楚: 'ソ',
  狙: 'ソ',
  疏: 'ソ',
  疎: 'ソ',
  礎: 'ソ',
  祖: 'ソ',
  租: 'ソ',
  粗: 'ソ',
  素: 'ソ',
  組: 'ソ',
  蘇: 'ソ',
  訴: 'ソ',
  阻: 'ソ',
  遡: 'ソ',
  鼠: 'ソ',
  僧: 'ソウ',
  創: 'ソウ',
  双: 'ソウ',
  叢: 'ソウ',
  倉: 'ソウ',
  喪: 'ソウ',
  壮: 'ソウ',
  奏: 'ソウ',
  爽: 'ソウ',
  宋: 'ソウ',
  層: 'ソウ',
  匝: 'キョウ',
  惣: 'ソウ',
  想: 'ソウ',
  捜: 'ソウ',
  掃: 'ソウ',
  挿: 'ソウ',
  掻: 'ソウ',
  操: 'ソウ',
  早: 'ソウ',
  曹: 'ソウ',
  巣: 'ソウ',
  槍: 'ソウ',
  槽: 'ソウ',
  漕: 'ソウ',
  燥: 'ソウ',
  争: 'ソウ',
  痩: 'ソウ',
  相: 'ソウ',
  窓: 'ソウ',
  糟: 'コウ',
  総: 'ソウ',
  綜: 'ソウ',
  聡: 'ソウ',
  草: 'ソウ',
  荘: 'ソウ',
  葬: 'ソウ',
  蒼: 'ソウ',
  藻: 'ソウ',
  装: 'ソウ',
  走: 'ソウ',
  送: 'ソウ',
  遭: 'ソウ',
  鎗: 'ソウ',
  霜: 'ソウ',
  騒: 'ソウ',
  像: 'ゾウ',
  増: 'ゾウ',
  憎: 'ゾウ',
  臓: 'ゾウ',
  蔵: 'ゾウ',
  贈: 'ゾウ',
  造: 'ゾウ',
  促: 'ソク',
  側: 'ソク',
  則: 'ソク',
  即: 'ソク',
  息: 'ソク',
  捉: 'ソク',
  束: 'ソク',
  測: 'ソク',
  足: 'ソク',
  速: 'ソク',
  俗: 'ゾク',
  属: 'ゾク',
  賊: 'ゾク',
  族: 'ゾク',
  続: 'ゾク',
  卒: 'ソツ',
  袖: 'シュウ',
  其: 'キ',
  揃: 'セン',
  存: 'ソン',
  孫: 'ソン',
  尊: 'ソン',
  損: 'ソン',
  村: 'ソン',
  遜: 'ソン',
  他: 'タ',
  多: 'タ',
  太: 'タイ',
  汰: 'タ',
  詑: 'イ',
  唾: 'ダ',
  堕: 'ダ',
  妥: 'ダ',
  惰: 'ダ',
  打: 'ダ',
  柁: 'ダ',
  舵: 'ダ',
  楕: 'ダ',
  陀: 'タ',
  駄: 'ダ',
  騨: 'タン',
  体: 'タイ',
  堆: 'タイ',
  対: 'タイ',
  耐: 'タイ',
  岱: 'タイ',
  帯: 'タイ',
  待: 'タイ',
  怠: 'タイ',
  態: 'タイ',
  戴: 'タイ',
  替: 'タイ',
  泰: 'タイ',
  滞: 'タイ',
  胎: 'タイ',
  腿: 'タイ',
  苔: 'タイ',
  袋: 'タイ',
  貸: 'タイ',
  退: 'タイ',
  逮: 'タイ',
  隊: 'タイ',
  黛: 'タイ',
  鯛: 'チョウ',
  代: 'ダイ',
  台: 'ダイ',
  大: 'ダイ',
  第: 'ダイ',
  醍: 'ダイ',
  題: 'ダイ',
  鷹: 'ヨウ',
  滝: 'ロウ',
  瀧: 'ロウ',
  卓: 'タク',
  啄: 'タク',
  宅: 'タク',
  托: 'タク',
  択: 'タク',
  拓: 'タク',
  沢: 'タク',
  濯: 'タク',
  琢: 'タク',
  託: 'タク',
  鐸: 'タク',
  濁: 'ダク',
  諾: 'ダク',
  茸: 'ジョウ',
  凧: 'いかのぼり',
  蛸: 'ソウ',
  只: 'シ',
  叩: 'コウ',
  但: 'タン',
  達: 'タツ',
  辰: 'シン',
  奪: 'ダツ',
  脱: 'ダツ',
  巽: 'ソン',
  竪: 'ジュ',
  辿: 'テン',
  棚: 'ホウ',
  谷: 'コク',
  狸: 'リ',
  鱈: 'セツ',
  樽: 'ソン',
  誰: 'スイ',
  丹: 'タン',
  単: 'タン',
  嘆: 'タン',
  坦: 'タン',
  担: 'タン',
  探: 'タン',
  旦: 'タン',
  歎: 'タン',
  淡: 'タン',
  湛: 'タン',
  炭: 'タン',
  短: 'タン',
  端: 'タン',
  箪: 'タン',
  綻: 'タン',
  耽: 'タン',
  胆: 'タン',
  蛋: 'タン',
  誕: 'タン',
  鍛: 'タン',
  団: 'ダン',
  壇: 'ダン',
  弾: 'ダン',
  断: 'ダン',
  暖: 'ダン',
  檀: 'ダン',
  段: 'ダン',
  男: 'ダン',
  談: 'ダン',
  値: 'チ',
  知: 'チ',
  地: 'チ',
  弛: 'チ',
  恥: 'チ',
  智: 'チ',
  池: 'チ',
  痴: 'チ',
  稚: 'チ',
  置: 'チ',
  致: 'チ',
  蜘: 'チ',
  遅: 'チ',
  馳: 'チ',
  築: 'チク',
  畜: 'チク',
  竹: 'チク',
  筑: 'チク',
  蓄: 'チク',
  逐: 'チク',
  秩: 'チツ',
  窒: 'チツ',
  茶: 'チャ',
  嫡: 'チャク',
  着: 'チャク',
  中: 'チュウ',
  仲: 'チュウ',
  宙: 'チュウ',
  忠: 'チュウ',
  抽: 'チュウ',
  昼: 'チュウ',
  柱: 'チュウ',
  注: 'チュウ',
  虫: 'チュウ',
  衷: 'チュウ',
  註: 'チュウ',
  酎: 'チュウ',
  鋳: 'チュウ',
  駐: 'チュウ',
  樗: 'チョ',
  瀦: 'チョ',
  猪: 'チョ',
  苧: 'チョ',
  著: 'チョ',
  貯: 'チョ',
  丁: 'チョウ',
  兆: 'チョウ',
  凋: 'チョウ',
  喋: 'チョウ',
  寵: 'チョウ',
  帖: 'チョウ',
  帳: 'チョウ',
  庁: 'チョウ',
  弔: 'チョウ',
  張: 'チョウ',
  彫: 'チョウ',
  徴: 'チョウ',
  懲: 'チョウ',
  挑: 'チョウ',
  暢: 'チョウ',
  朝: 'チョウ',
  潮: 'チョウ',
  牒: 'チョウ',
  町: 'チョウ',
  眺: 'チョウ',
  聴: 'チョウ',
  脹: 'チョウ',
  腸: 'チョウ',
  蝶: 'チョウ',
  調: 'チョウ',
  諜: 'チョウ',
  超: 'チョウ',
  跳: 'チョウ',
  銚: 'チョウ',
  長: 'チョウ',
  頂: 'チョウ',
  鳥: 'チョウ',
  勅: 'チョク',
  捗: 'チョク',
  直: 'チョク',
  朕: 'チン',
  沈: 'チン',
  珍: 'チン',
  賃: 'チン',
  鎮: 'チン',
  陳: 'チン',
  津: 'シン',
  墜: 'ツイ',
  椎: 'ツイ',
  槌: 'ツイ',
  追: 'ツイ',
  鎚: 'ツイ',
  痛: 'ツウ',
  通: 'ツウ',
  塚: 'チョウ',
  栂: 'つが',
  掴: 'カク',
  槻: 'キ',
  佃: 'テン',
  漬: 'シ',
  柘: 'シャ',
  辻: 'つじ',
  蔦: 'チョウ',
  綴: 'テイ',
  鍔: 'ガク',
  椿: 'チン',
  潰: 'カイ',
  坪: 'ヘイ',
  壷: 'コ',
  嬬: 'ジュ',
  紬: 'チュウ',
  爪: 'ソウ',
  吊: 'チョウ',
  釣: 'チョウ',
  鶴: 'カク',
  亭: 'テイ',
  低: 'テイ',
  停: 'テイ',
  偵: 'テイ',
  剃: 'テイ',
  貞: 'テイ',
  呈: 'テイ',
  堤: 'テイ',
  定: 'テイ',
  帝: 'テイ',
  底: 'テイ',
  庭: 'テイ',
  廷: 'テイ',
  弟: 'テイ',
  悌: 'テイ',
  抵: 'テイ',
  挺: 'チョウ',
  提: 'テイ',
  梯: 'テイ',
  汀: 'テイ',
  碇: 'テイ',
  禎: 'テイ',
  程: 'テイ',
  締: 'テイ',
  艇: 'テイ',
  訂: 'テイ',
  諦: 'テイ',
  蹄: 'テイ',
  逓: 'テイ',
  邸: 'テイ',
  鄭: 'テイ',
  釘: 'テイ',
  鼎: 'テイ',
  泥: 'デイ',
  摘: 'テキ',
  擢: 'テキ',
  敵: 'テキ',
  滴: 'テキ',
  的: 'テキ',
  笛: 'テキ',
  適: 'テキ',
  鏑: 'テキ',
  溺: 'デキ',
  哲: 'テツ',
  徹: 'テツ',
  撤: 'テツ',
  轍: 'テツ',
  迭: 'テツ',
  鉄: 'テツ',
  典: 'テン',
  填: 'テン',
  天: 'テン',
  展: 'テン',
  店: 'テン',
  添: 'テン',
  纏: 'テン',
  甜: 'テン',
  貼: 'テン',
  転: 'テン',
  顛: 'テン',
  点: 'テン',
  伝: 'デン',
  殿: 'デン',
  澱: 'デン',
  田: 'デン',
  電: 'デン',
  兎: 'ト',
  吐: 'ト',
  堵: 'ト',
  塗: 'ト',
  妬: 'ト',
  屠: 'ト',
  徒: 'ト',
  斗: 'ト',
  杜: 'ト',
  渡: 'ト',
  登: 'トウ',
  菟: 'ト',
  賭: 'ト',
  途: 'ト',
  都: 'ト',
  鍍: 'ト',
  砥: 'シ',
  砺: 'レイ',
  努: 'ド',
  度: 'ド',
  土: 'ド',
  奴: 'ド',
  怒: 'ド',
  倒: 'トウ',
  党: 'トウ',
  冬: 'トウ',
  凍: 'トウ',
  刀: 'トウ',
  唐: 'トウ',
  塔: 'トウ',
  塘: 'トウ',
  套: 'トウ',
  宕: 'トウ',
  島: 'トウ',
  嶋: 'トウ',
  悼: 'トウ',
  投: 'トウ',
  搭: 'トウ',
  東: 'トウ',
  桃: 'トウ',
  梼: 'トウ',
  棟: 'トウ',
  盗: 'トウ',
  淘: 'トウ',
  湯: 'トウ',
  涛: 'トウ',
  灯: 'トウ',
  燈: 'トウ',
  当: 'トウ',
  痘: 'トウ',
  祷: 'トウ',
  等: 'トウ',
  答: 'トウ',
  筒: 'トウ',
  糖: 'トウ',
  統: 'トウ',
  到: 'トウ',
  董: 'トウ',
  蕩: 'トウ',
  藤: 'トウ',
  討: 'トウ',
  謄: 'トウ',
  豆: 'トウ',
  踏: 'トウ',
  逃: 'トウ',
  透: 'トウ',
  鐙: 'トウ',
  陶: 'トウ',
  頭: 'トウ',
  騰: 'トウ',
  闘: 'トウ',
  働: 'ドウ',
  動: 'ドウ',
  同: 'ドウ',
  堂: 'ドウ',
  導: 'ドウ',
  憧: 'ショウ',
  撞: 'ドウ',
  洞: 'ドウ',
  瞳: 'ドウ',
  童: 'ドウ',
  胴: 'ドウ',
  萄: 'ドウ',
  道: 'ドウ',
  銅: 'ドウ',
  峠: 'とうげ',
  鴇: 'ホウ',
  匿: 'トク',
  得: 'トク',
  徳: 'トク',
  涜: 'トク',
  特: 'トク',
  督: 'トク',
  禿: 'トク',
  篤: 'トク',
  毒: 'ドク',
  独: 'ドク',
  読: 'ドク',
  栃: 'とち',
  橡: 'ショウ',
  凸: 'トツ',
  突: 'トツ',
  椴: 'タン',
  届: 'カイ',
  鳶: 'エン',
  苫: 'セン',
  寅: 'イン',
  酉: 'ユウ',
  瀞: 'セイ',
  噸: 'トン',
  屯: 'トン',
  惇: 'シュン',
  敦: 'トン',
  沌: 'トン',
  豚: 'トン',
  遁: 'トン',
  頓: 'トン',
  呑: 'トン',
  曇: 'ドン',
  鈍: 'ドン',
  奈: 'ナ',
  那: 'ナ',
  内: 'ナイ',
  乍: 'サ',
  凪: 'なぎ',
  薙: 'テイ',
  謎: 'メイ',
  灘: 'タン',
  捺: 'ナツ',
  鍋: 'カ',
  楢: 'シュウ',
  馴: 'ジュン',
  縄: 'ジョウ',
  畷: 'テツ',
  南: 'ナン',
  楠: 'ナン',
  軟: 'ナン',
  難: 'ナン',
  汝: 'ジョ',
  二: 'ニ',
  尼: 'ニ',
  弐: 'ニ',
  迩: 'ジ',
  匂: 'にお.う',
  賑: 'シン',
  肉: 'ニク',
  虹: 'コウ',
  廿: 'ジュウ',
  日: 'ニチ',
  乳: 'ニュウ',
  入: 'ニュウ',
  如: 'ジョ',
  尿: 'ニョウ',
  韮: 'キュウ',
  任: 'ニン',
  妊: 'ニン',
  忍: 'ニン',
  認: 'ニン',
  濡: 'ジュ',
  禰: 'ネ',
  祢: 'ネ',
  寧: 'ネイ',
  葱: 'ソウ',
  猫: 'ビョウ',
  熱: 'ネツ',
  年: 'ネン',
  念: 'ネン',
  捻: 'ネン',
  撚: 'ネン',
  燃: 'ネン',
  粘: 'ネン',
  乃: 'ナイ',
  廼: 'ナイ',
  之: 'シ',
  埜: 'ヤ',
  嚢: 'ノウ',
  悩: 'ノウ',
  濃: 'ノウ',
  納: 'ノウ',
  能: 'ノウ',
  脳: 'ノウ',
  膿: 'ノウ',
  農: 'ノウ',
  覗: 'シ',
  蚤: 'ソウ',
  巴: 'ハ',
  把: 'ハ',
  播: 'ハ',
  覇: 'ハ',
  杷: 'ハ',
  波: 'ハ',
  派: 'ハ',
  琶: 'ハ',
  破: 'ハ',
  婆: 'バ',
  罵: 'バ',
  芭: 'バ',
  馬: 'バ',
  俳: 'ハイ',
  廃: 'ハイ',
  拝: 'ハイ',
  排: 'ハイ',
  敗: 'ハイ',
  杯: 'ハイ',
  盃: 'ハイ',
  牌: 'ハイ',
  背: 'ハイ',
  肺: 'ハイ',
  輩: 'ハイ',
  配: 'ハイ',
  倍: 'バイ',
  培: 'バイ',
  媒: 'バイ',
  梅: 'バイ',
  楳: 'バイ',
  煤: 'バイ',
  狽: 'ケン',
  買: 'バイ',
  売: 'バイ',
  賠: 'バイ',
  陪: 'バイ',
  這: 'シャ',
  蝿: 'ヨウ',
  秤: 'ショウ',
  矧: 'シン',
  萩: 'シュウ',
  伯: 'ハク',
  剥: 'ハク',
  博: 'ハク',
  拍: 'ハク',
  柏: 'ハク',
  泊: 'ハク',
  白: 'ハク',
  箔: 'ハク',
  粕: 'ハク',
  舶: 'ハク',
  薄: 'ハク',
  迫: 'ハク',
  曝: 'バク',
  漠: 'バク',
  爆: 'バク',
  縛: 'バク',
  莫: 'バク',
  駁: 'ハク',
  麦: 'バク',
  函: 'カン',
  箱: 'ソウ',
  硲: 'はざま',
  箸: 'チョ',
  肇: 'チョウ',
  筈: 'カツ',
  櫨: 'ロ',
  幡: 'マン',
  肌: 'キ',
  畑: 'はた',
  畠: 'はたけ',
  八: 'ハチ',
  鉢: 'ハチ',
  溌: 'ハツ',
  発: 'ハツ',
  醗: 'ハツ',
  髪: 'ハツ',
  伐: 'バツ',
  罰: 'バツ',
  抜: 'バツ',
  筏: 'バツ',
  閥: 'バツ',
  鳩: 'キュウ',
  噺: 'はなし',
  塙: 'カク',
  蛤: 'コウ',
  隼: 'シュン',
  伴: 'ハン',
  判: 'ハン',
  半: 'ハン',
  反: 'ハン',
  叛: 'ハン',
  帆: 'ハン',
  搬: 'ハン',
  斑: 'ハン',
  板: 'ハン',
  氾: 'ハン',
  汎: 'ハン',
  版: 'ハン',
  犯: 'ハン',
  班: 'ハン',
  畔: 'ハン',
  繁: 'ハン',
  般: 'ハン',
  藩: 'ハン',
  販: 'ハン',
  範: 'ハン',
  釆: 'ハン',
  煩: 'ハン',
  頒: 'ハン',
  飯: 'ハン',
  挽: 'バン',
  晩: 'バン',
  番: 'バン',
  盤: 'バン',
  磐: 'バン',
  蕃: 'バン',
  蛮: 'バン',
  匪: 'ヒ',
  卑: 'ヒ',
  否: 'ヒ',
  妃: 'ヒ',
  庇: 'ヒ',
  彼: 'ヒ',
  悲: 'ヒ',
  扉: 'ヒ',
  批: 'ヒ',
  披: 'ヒ',
  斐: 'ヒ',
  比: 'ヒ',
  泌: 'ヒツ',
  疲: 'ヒ',
  皮: 'ヒ',
  碑: 'ヒ',
  秘: 'ヒ',
  緋: 'ヒ',
  罷: 'ヒ',
  肥: 'ヒ',
  被: 'ヒ',
  誹: 'ヒ',
  費: 'ヒ',
  避: 'ヒ',
  非: 'ヒ',
  飛: 'ヒ',
  樋: 'トウ',
  簸: 'ハ',
  備: 'ビ',
  尾: 'ビ',
  微: 'ビ',
  枇: 'ビ',
  毘: 'ヒ',
  琵: 'ビ',
  眉: 'ビ',
  美: 'ビ',
  鼻: 'ビ',
  柊: 'シュ',
  稗: 'ハイ',
  匹: 'ヒツ',
  疋: 'ヒキ',
  髭: 'シ',
  彦: 'ゲン',
  膝: 'シツ',
  菱: 'リョウ',
  肘: 'チュウ',
  弼: 'ヒツ',
  必: 'ヒツ',
  畢: 'ヒツ',
  筆: 'ヒツ',
  逼: 'フク',
  桧: 'カイ',
  姫: 'キ',
  媛: 'エン',
  紐: 'チュウ',
  百: 'ヒャク',
  謬: 'ビュウ',
  俵: 'ヒョウ',
  彪: 'ヒョウ',
  標: 'ヒョウ',
  氷: 'ヒョウ',
  漂: 'ヒョウ',
  瓢: 'ヒョウ',
  票: 'ヒョウ',
  表: 'ヒョウ',
  評: 'ヒョウ',
  豹: 'ヒョウ',
  廟: 'ビョウ',
  描: 'ビョウ',
  病: 'ビョウ',
  秒: 'ビョウ',
  苗: 'ビョウ',
  錨: 'ビョウ',
  鋲: 'びょう',
  蒜: 'サン',
  蛭: 'シツ',
  鰭: 'キ',
  品: 'ヒン',
  彬: 'ヒン',
  斌: 'ヒン',
  浜: 'ヒン',
  瀕: 'ヒン',
  貧: 'ヒン',
  賓: 'ヒン',
  頻: 'ヒン',
  敏: 'ビン',
  瓶: 'ビン',
  不: 'フ',
  付: 'フ',
  埠: 'フ',
  夫: 'フ',
  婦: 'フ',
  富: 'フ',
  冨: 'フ',
  布: 'フ',
  府: 'フ',
  怖: 'フ',
  扶: 'フ',
  敷: 'フ',
  斧: 'フ',
  普: 'フ',
  浮: 'フ',
  父: 'フ',
  符: 'フ',
  腐: 'フ',
  膚: 'フ',
  芙: 'フ',
  譜: 'フ',
  負: 'フ',
  賦: 'フ',
  赴: 'フ',
  阜: 'フ',
  附: 'フ',
  侮: 'ブ',
  撫: 'ブ',
  武: 'ブ',
  舞: 'ブ',
  葡: 'ブ',
  蕪: 'ブ',
  部: 'ブ',
  封: 'フウ',
  楓: 'フウ',
  風: 'フウ',
  葺: 'シュウ',
  蕗: 'ロ',
  伏: 'フク',
  副: 'フク',
  復: 'フク',
  幅: 'フク',
  服: 'フク',
  福: 'フク',
  腹: 'フク',
  複: 'フク',
  覆: 'フク',
  淵: 'エン',
  弗: 'フツ',
  払: 'フツ',
  沸: 'フツ',
  仏: 'ブツ',
  物: 'ブツ',
  鮒: 'ホ',
  分: 'ブン',
  吻: 'フン',
  噴: 'フン',
  墳: 'フン',
  憤: 'フン',
  扮: 'フン',
  焚: 'フン',
  奮: 'フン',
  粉: 'フン',
  糞: 'フン',
  紛: 'フン',
  雰: 'フン',
  文: 'ブン',
  聞: 'ブン',
  丙: 'ヘイ',
  併: 'ヘイ',
  兵: 'ヘイ',
  塀: 'ヘイ',
  幣: 'ヘイ',
  平: 'ヘイ',
  弊: 'ヘイ',
  柄: 'ヘイ',
  並: 'ヘイ',
  蔽: 'ヘイ',
  閉: 'ヘイ',
  陛: 'ヘイ',
  米: 'ベイ',
  頁: 'ケツ',
  僻: 'ヘキ',
  壁: 'ヘキ',
  癖: 'ヘキ',
  碧: 'ヘキ',
  別: 'ベツ',
  瞥: 'ベツ',
  蔑: 'ベツ',
  箆: 'ヘイ',
  偏: 'ヘン',
  変: 'ヘン',
  片: 'ヘン',
  篇: 'ヘン',
  編: 'ヘン',
  辺: 'ヘン',
  返: 'ヘン',
  遍: 'ヘン',
  便: 'ベン',
  勉: 'ベン',
  娩: 'ベン',
  弁: 'ベン',
  鞭: 'ベン',
  保: 'ホ',
  舗: 'ホ',
  鋪: 'ホ',
  圃: 'ホ',
  捕: 'ホ',
  歩: 'ホ',
  甫: 'ホ',
  補: 'ホ',
  輔: 'ホ',
  穂: 'スイ',
  募: 'ボ',
  墓: 'ボ',
  慕: 'ボ',
  戊: 'ボ',
  暮: 'ボ',
  母: 'ボ',
  簿: 'ボ',
  菩: 'ボ',
  倣: 'ホウ',
  俸: 'ホウ',
  包: 'ホウ',
  呆: 'ホウ',
  報: 'ホウ',
  奉: 'ホウ',
  宝: 'ホウ',
  峰: 'ホウ',
  峯: 'ホウ',
  崩: 'ホウ',
  庖: 'ホウ',
  抱: 'ホウ',
  捧: 'ホウ',
  放: 'ホウ',
  方: 'ホウ',
  朋: 'ホウ',
  法: 'ホウ',
  泡: 'ホウ',
  烹: 'ホウ',
  砲: 'ホウ',
  縫: 'ホウ',
  胞: 'ホウ',
  芳: 'ホウ',
  萌: 'ホウ',
  蓬: 'ホウ',
  蜂: 'ホウ',
  褒: 'ホウ',
  訪: 'ホウ',
  豊: 'ホウ',
  邦: 'ホウ',
  鋒: 'ホウ',
  飽: 'ホウ',
  鳳: 'ホウ',
  鵬: 'ホウ',
  乏: 'ボウ',
  亡: 'ボウ',
  傍: 'ボウ',
  剖: 'ボウ',
  坊: 'ボウ',
  妨: 'ボウ',
  帽: 'ボウ',
  忘: 'ボウ',
  忙: 'ボウ',
  房: 'ボウ',
  暴: 'ボウ',
  望: 'ボウ',
  某: 'ボウ',
  棒: 'ボウ',
  冒: 'ボウ',
  紡: 'ボウ',
  肪: 'ボウ',
  膨: 'ボウ',
  謀: 'ボウ',
  貌: 'ボウ',
  貿: 'ボウ',
  鉾: 'ボウ',
  防: 'ボウ',
  吠: 'ハイ',
  頬: 'キョウ',
  北: 'ホク',
  僕: 'ボク',
  卜: 'ボク',
  墨: 'ボク',
  撲: 'ボク',
  朴: 'ボク',
  牧: 'ボク',
  睦: 'ボク',
  穆: 'ボク',
  釦: 'コウ',
  勃: 'ボツ',
  没: 'ボツ',
  殆: 'タイ',
  堀: 'クツ',
  幌: 'コウ',
  奔: 'ホン',
  本: 'ホン',
  翻: 'ホン',
  凡: 'ボン',
  盆: 'ボン',
  摩: 'マ',
  磨: 'マ',
  魔: 'マ',
  麻: 'マ',
  埋: 'マイ',
  妹: 'マイ',
  昧: 'マイ',
  枚: 'マイ',
  毎: 'マイ',
  哩: 'リ',
  槙: 'テン',
  幕: 'マク',
  膜: 'マク',
  枕: 'チン',
  鮪: 'キ',
  柾: 'まさ',
  鱒: 'ソン',
  桝: 'ます',
  亦: 'エキ',
  俣: 'また',
  又: 'ユウ',
  抹: 'マツ',
  末: 'マツ',
  沫: 'マツ',
  迄: 'キツ',
  侭: 'ジン',
  繭: 'ケン',
  麿: 'まろ',
  万: 'マン',
  慢: 'マン',
  満: 'マン',
  漫: 'マン',
  蔓: 'マン',
  味: 'ミ',
  未: 'ミ',
  魅: 'ミ',
  巳: 'シ',
  箕: 'キ',
  岬: 'コウ',
  密: 'ミツ',
  蜜: 'ミツ',
  湊: 'ソウ',
  蓑: 'サ',
  稔: 'ネン',
  脈: 'ミャク',
  妙: 'ミョウ',
  粍: 'ミリ',
  民: 'ミン',
  眠: 'ミン',
  務: 'ム',
  夢: 'ム',
  無: 'ム',
  牟: 'ボウ',
  矛: 'ム',
  霧: 'ム',
  鵡: 'ブ',
  椋: 'リョウ',
  婿: 'セイ',
  娘: 'ジョウ',
  冥: 'メイ',
  名: 'メイ',
  命: 'メイ',
  明: 'メイ',
  盟: 'メイ',
  迷: 'メイ',
  銘: 'メイ',
  鳴: 'メイ',
  姪: 'テツ',
  牝: 'ヒン',
  滅: 'メツ',
  免: 'メン',
  棉: 'メン',
  綿: 'メン',
  緬: 'メン',
  面: 'メン',
  麺: 'メン',
  摸: 'モ',
  模: 'モ',
  茂: 'モ',
  妄: 'モウ',
  孟: 'モウ',
  毛: 'モウ',
  猛: 'モウ',
  盲: 'モウ',
  網: 'モウ',
  耗: 'モウ',
  蒙: 'モウ',
  儲: 'チョ',
  木: 'ボク',
  黙: 'モク',
  目: 'モク',
  杢: 'もく',
  勿: 'モチ',
  餅: 'ヘイ',
  尤: 'ユウ',
  戻: 'レイ',
  籾: 'もみ',
  貰: 'セイ',
  問: 'モン',
  悶: 'モン',
  紋: 'モン',
  門: 'モン',
  匁: 'もんめ',
  也: 'ヤ',
  冶: 'ヤ',
  夜: 'ヤ',
  爺: 'ヤ',
  耶: 'ヤ',
  野: 'ヤ',
  弥: 'ミ',
  矢: 'シ',
  厄: 'ヤク',
  役: 'ヤク',
  約: 'ヤク',
  薬: 'ヤク',
  訳: 'ヤク',
  躍: 'ヤク',
  靖: 'セイ',
  柳: 'リュウ',
  薮: 'ソウ',
  鑓: 'やり',
  愉: 'ユ',
  愈: 'ユ',
  油: 'ユ',
  癒: 'ユ',
  諭: 'ユ',
  輸: 'ユ',
  唯: 'ユイ',
  佑: 'ユウ',
  優: 'ユウ',
  勇: 'ユウ',
  友: 'ユウ',
  宥: 'ユウ',
  幽: 'ユウ',
  悠: 'ユウ',
  憂: 'ユウ',
  揖: 'イツ',
  有: 'ユウ',
  柚: 'ユ',
  湧: 'ユウ',
  涌: 'ユウ',
  猶: 'ユウ',
  猷: 'ユウ',
  由: 'ユ',
  祐: 'ユウ',
  裕: 'ユウ',
  誘: 'ユウ',
  遊: 'ユウ',
  邑: 'ユウ',
  郵: 'ユウ',
  雄: 'ユウ',
  融: 'ユウ',
  夕: 'セキ',
  予: 'ヨ',
  余: 'ヨ',
  与: 'ヨ',
  誉: 'ヨ',
  輿: 'ヨ',
  預: 'ヨ',
  傭: 'ヨウ',
  幼: 'ヨウ',
  妖: 'ヨウ',
  容: 'ヨウ',
  庸: 'ヨウ',
  揚: 'ヨウ',
  揺: 'ヨウ',
  擁: 'ヨウ',
  曜: 'ヨウ',
  楊: 'ヨウ',
  様: 'ヨウ',
  洋: 'ヨウ',
  溶: 'ヨウ',
  熔: 'ヨウ',
  用: 'ヨウ',
  窯: 'ヨウ',
  羊: 'ヨウ',
  耀: 'ヨウ',
  葉: 'ヨウ',
  蓉: 'ヨウ',
  要: 'ヨウ',
  謡: 'ヨウ',
  踊: 'ヨウ',
  遥: 'ヨウ',
  陽: 'ヨウ',
  養: 'ヨウ',
  慾: 'ヨク',
  抑: 'ヨク',
  欲: 'ヨク',
  沃: 'ヨウ',
  浴: 'ヨク',
  翌: 'ヨク',
  翼: 'ヨク',
  淀: 'テン',
  羅: 'ラ',
  螺: 'ラ',
  裸: 'ラ',
  来: 'ライ',
  莱: 'ライ',
  頼: 'ライ',
  雷: 'ライ',
  洛: 'ラク',
  絡: 'ラク',
  落: 'ラク',
  酪: 'ラク',
  乱: 'ラン',
  卵: 'ラン',
  嵐: 'ラン',
  欄: 'ラン',
  濫: 'ラン',
  藍: 'ラン',
  蘭: 'ラン',
  覧: 'ラン',
  利: 'リ',
  吏: 'リ',
  履: 'リ',
  李: 'リ',
  梨: 'リ',
  理: 'リ',
  璃: 'リ',
  痢: 'リ',
  裏: 'リ',
  裡: 'リ',
  里: 'リ',
  離: 'リ',
  陸: 'リク',
  律: 'リツ',
  率: 'ソツ',
  立: 'リツ',
  葎: 'リツ',
  掠: 'リャク',
  略: 'リャク',
  劉: 'リュウ',
  流: 'リュウ',
  溜: 'リュウ',
  琉: 'リュウ',
  留: 'リュウ',
  硫: 'リュウ',
  粒: 'リュウ',
  隆: 'リュウ',
  竜: 'リュウ',
  龍: 'リュウ',
  侶: 'リョ',
  慮: 'リョ',
  旅: 'リョ',
  虜: 'リョ',
  了: 'リョウ',
  亮: 'リョウ',
  僚: 'リョウ',
  両: 'リョウ',
  凌: 'リョウ',
  寮: 'リョウ',
  料: 'リョウ',
  梁: 'リョウ',
  涼: 'リョウ',
  猟: 'リョウ',
  療: 'リョウ',
  瞭: 'リョウ',
  稜: 'リョウ',
  糧: 'リョウ',
  良: 'リョウ',
  諒: 'リョウ',
  遼: 'リョウ',
  量: 'リョウ',
  陵: 'リョウ',
  領: 'リョウ',
  力: 'リョク',
  緑: 'リョク',
  倫: 'リン',
  厘: 'リン',
  林: 'リン',
  淋: 'リン',
  燐: 'リン',
  琳: 'リン',
  臨: 'リン',
  輪: 'リン',
  隣: 'リン',
  鱗: 'リン',
  麟: 'リン',
  瑠: 'ル',
  塁: 'ルイ',
  涙: 'ルイ',
  累: 'ルイ',
  類: 'ルイ',
  令: 'レイ',
  伶: 'レイ',
  例: 'レイ',
  冷: 'レイ',
  励: 'レイ',
  嶺: 'レイ',
  怜: 'レイ',
  玲: 'レイ',
  礼: 'レイ',
  苓: 'レイ',
  鈴: 'レイ',
  隷: 'レイ',
  零: 'レイ',
  霊: 'レイ',
  麗: 'レイ',
  齢: 'レイ',
  暦: 'レキ',
  歴: 'レキ',
  列: 'レツ',
  劣: 'レツ',
  烈: 'レツ',
  裂: 'レツ',
  廉: 'レン',
  恋: 'レン',
  憐: 'レン',
  漣: 'レン',
  煉: 'レン',
  簾: 'レン',
  練: 'レン',
  聯: 'レン',
  蓮: 'レン',
  連: 'レン',
  錬: 'レン',
  呂: 'ロ',
  魯: 'ロ',
  櫓: 'ロ',
  炉: 'ロ',
  賂: 'ロ',
  路: 'ロ',
  露: 'ロ',
  労: 'ロウ',
  婁: 'ル',
  廊: 'ロウ',
  弄: 'ロウ',
  朗: 'ロウ',
  楼: 'ロウ',
  榔: 'ロウ',
  浪: 'ロウ',
  漏: 'ロウ',
  牢: 'ロウ',
  狼: 'ロウ',
  篭: 'ロウ',
  老: 'ロウ',
  聾: 'ロウ',
  蝋: 'ロウ',
  郎: 'ロウ',
  六: 'ロク',
  麓: 'ロク',
  禄: 'ロク',
  肋: 'ロク',
  録: 'ロク',
  論: 'ロン',
  倭: 'ワ',
  和: 'ワ',
  話: 'ワ',
  歪: 'ワイ',
  賄: 'ワイ',
  脇: 'キョウ',
  惑: 'ワク',
  枠: 'わく',
  鷲: 'シュウ',
  亙: 'コウ',
  亘: 'コウ',
  鰐: 'ガク',
  詫: 'タ',
  藁: 'コウ',
  蕨: 'ケツ',
  椀: 'ワン',
  湾: 'ワン',
  碗: 'ワン',
  腕: 'ワン',
  弌: 'イチ',
  丐: 'カイ',
  丕: 'ヒ',
  个: 'カ',
  丱: 'カン',
  丶: 'チュ',
  丼: 'トン',
  丿: 'ヘツ',
  乂: 'ガイ',
  乖: 'カイ',
  乘: 'ジョウ',
  亂: 'ラン',
  亅: 'ケツ',
  豫: 'ヨ',
  亊: 'ジ',
  舒: 'ジョ',
  弍: 'ニ',
  于: 'ウ',
  亞: 'ア',
  亟: 'キョク',
  亠: 'トウ',
  亢: 'コウ',
  亰: 'キョウ',
  亳: 'ハク',
  亶: 'タン',
  从: 'ショウ',
  仍: 'ジョウ',
  仄: 'ソク',
  仆: 'フ',
  仂: 'ドウ',
  仗: 'ジョウ',
  仞: 'ジン',
  仭: 'ジン',
  仟: 'セン',
  价: 'カイ',
  伉: 'コウ',
  佚: 'イツ',
  估: 'コ',
  佛: 'ブツ',
  佝: 'コウ',
  佗: 'タ',
  佇: 'チョ',
  佶: 'キツ',
  侈: 'シ',
  侏: 'シュ',
  侘: 'タ',
  佻: 'チョウ',
  佩: 'ハイ',
  佰: 'ヒャク',
  侑: 'ユウ',
  佯: 'ヨウ',
  來: 'ライ',
  侖: 'リン',
  儘: 'ジン',
  俔: 'ケン',
  俟: 'シ',
  俎: 'ソ',
  俘: 'フ',
  俛: 'フ',
  俑: 'ヨウ',
  俚: 'リ',
  俐: 'リ',
  俤: 'おもかげ',
  俥: 'くるま',
  倚: 'イ',
  倨: 'キョ',
  倔: 'クツ',
  倪: 'ゲイ',
  倥: 'コウ',
  倅: 'サイ',
  伜: 'サイ',
  俶: 'シュク',
  倡: 'ショウ',
  倩: 'セン',
  倬: 'タク',
  俾: 'フ',
  俯: 'フ',
  們: 'モン',
  倆: 'リョウ',
  偃: 'エン',
  假: 'カ',
  會: 'カイ',
  偕: 'カイ',
  偐: 'ゲン',
  偈: 'ゲ',
  做: 'サ',
  偖: 'シャ',
  偬: 'ソウ',
  偸: 'ツ',
  傀: 'カイ',
  傚: 'コウ',
  傅: 'フ',
  傴: 'ク',
  傲: 'ゴウ',
  僉: 'セン',
  僊: 'セン',
  傳: 'テン',
  僂: 'ロウ',
  僖: 'キ',
  僞: 'ギ',
  僥: 'ギョウ',
  僭: 'シン',
  僣: 'シン',
  僮: 'トウ',
  價: 'カ',
  僵: 'キョウ',
  儉: 'ケン',
  儁: 'シュン',
  儂: 'ドウ',
  儖: 'ラン',
  儕: 'セイ',
  儔: 'チュウ',
  儚: 'ボウ',
  儡: 'ライ',
  儺: 'ナ',
  儷: 'レイ',
  儼: 'ゲン',
  儻: 'トウ',
  儿: 'ジン',
  兀: 'コツ',
  兒: 'ジ',
  兌: 'ダ',
  兔: 'ト',
  兢: 'キョウ',
  竸: 'キョウ',
  兩: 'リョウ',
  兪: 'ツ',
  兮: 'ケイ',
  冀: 'キ',
  冂: 'キョウ',
  囘: 'カイ',
  册: 'サツ',
  冉: 'ゼン',
  冏: 'ケイ',
  冑: 'チュウ',
  冓: 'コウ',
  冕: 'ベン',
  冖: 'ベキ',
  冤: 'エン',
  冦: 'コウ',
  冢: 'チョウ',
  冩: 'シャ',
  冪: 'ベキ',
  冫: 'ヒョウ',
  决: 'ケチ',
  冱: 'ゴ',
  冲: 'チュウ',
  冰: 'ヒョウ',
  况: 'キョウ',
  冽: 'レツ',
  凅: 'コ',
  凉: 'リョウ',
  凛: 'リン',
  几: 'キ',
  處: 'ショ',
  凩: 'こがらし',
  凭: 'ヒョウ',
  凰: 'コウ',
  凵: 'カン',
  凾: 'カン',
  刄: 'ジン',
  刋: 'カン',
  刔: 'ケツ',
  刎: 'フン',
  刧: 'コウ',
  刪: 'サン',
  刮: 'カツ',
  刳: 'コ',
  刹: 'セチ',
  剏: 'ソウ',
  剄: 'ケイ',
  剋: 'コク',
  剌: 'ラツ',
  剞: 'キ',
  剔: 'テキ',
  剪: 'セン',
  剴: 'ガイ',
  剩: 'ジョウ',
  剳: 'トウ',
  剿: 'ソウ',
  剽: 'ヒョウ',
  劍: 'ケン',
  劔: 'ケン',
  劒: 'ケン',
  剱: 'ケン',
  劈: 'ヘキ',
  劑: 'ザイ',
  辨: 'ベン',
  辧: 'ベン',
  劬: 'ク',
  劭: 'ショウ',
  劼: 'カツ',
  劵: 'ケン',
  勁: 'ケイ',
  勍: 'ケイ',
  勗: 'キョク',
  勞: 'ロウ',
  勣: 'セキ',
  勦: 'ソウ',
  飭: 'チョク',
  勠: 'リク',
  勳: 'クン',
  勵: 'レイ',
  勸: 'カン',
  勹: 'ホウ',
  匆: 'ソウ',
  匈: 'キョウ',
  甸: 'デン',
  匍: 'ホ',
  匐: 'ホク',
  匏: 'ホ',
  匕: 'ヒ',
  匚: 'ホウ',
  匣: 'コウ',
  匯: 'キ',
  匱: 'キ',
  匳: 'レン',
  匸: 'ケイ',
  區: 'ク',
  卆: 'ソツ',
  卅: 'ソウ',
  丗: 'セイ',
  卉: 'キ',
  卍: 'バン',
  凖: 'ジュン',
  卞: 'ベン',
  卩: 'セツ',
  卮: 'シ',
  夘: 'ボウ',
  卻: 'キャク',
  卷: 'カン',
  厂: 'カン',
  厖: 'ボウ',
  厠: 'シ',
  厦: 'カ',
  厥: 'ケツ',
  厮: 'シ',
  厰: 'ショウ',
  厶: 'シ',
  參: 'サン',
  簒: 'サン',
  雙: 'ソウ',
  叟: 'ソウ',
  曼: 'マン',
  燮: 'ショウ',
  叮: 'テイ',
  叨: 'トウ',
  叭: 'ハツ',
  叺: 'かます',
  吁: 'ク',
  吽: 'コウ',
  呀: 'ガ',
  听: 'キン',
  吭: 'コウ',
  吼: 'コウ',
  吮: 'セン',
  吶: 'トツ',
  吩: 'フン',
  吝: 'リン',
  呎: 'シャク',
  咏: 'エイ',
  呵: 'カ',
  咎: 'キュウ',
  呟: 'ゲン',
  呱: 'コ',
  呷: 'コウ',
  呰: 'シ',
  咒: 'ジュ',
  呻: 'シン',
  咀: 'ソ',
  呶: 'ド',
  咄: 'トツ',
  咐: 'フ',
  咆: 'ホウ',
  哇: 'ア',
  咢: 'ガク',
  咸: 'カン',
  咥: 'テツ',
  咬: 'コウ',
  哄: 'コウ',
  哈: 'ゴウ',
  咨: 'シ',
  咫: 'シ',
  哂: 'シン',
  咤: 'タ',
  咾: 'ロウ',
  咼: 'カイ',
  哘: 'さそ.う',
  哥: 'カ',
  哦: 'ガ',
  唏: 'キ',
  唔: 'ゴ',
  哽: 'コウ',
  哮: 'コウ',
  哭: 'コク',
  哺: 'ホ',
  哢: 'ロウ',
  唹: 'オ',
  啀: 'ガイ',
  啣: 'カン',
  啌: 'クウ',
  售: 'シュウ',
  啜: 'セツ',
  啅: 'タク',
  啖: 'タン',
  啗: 'タン',
  唸: 'テン',
  唳: 'レイ',
  啝: 'ワ',
  喙: 'カイ',
  喀: 'カク',
  咯: 'カク',
  喊: 'カン',
  喟: 'キ',
  啻: 'シ',
  啾: 'シュウ',
  喘: 'ゼン',
  喞: 'ショク',
  單: 'タン',
  啼: 'テイ',
  喃: 'ナン',
  喩: 'ユ',
  喇: 'ラツ',
  喨: 'リョウ',
  嗚: 'ウ',
  嗅: 'キュウ',
  嗟: 'サ',
  嗄: 'サ',
  嗜: 'シ',
  嗤: 'シ',
  嗔: 'シン',
  嘔: 'オウ',
  嗷: 'ゴウ',
  嘖: 'サク',
  嗾: 'ゾク',
  嗽: 'ソウ',
  嘛: 'マ',
  嗹: 'レン',
  噎: 'イツ',
  噐: 'キ',
  營: 'エイ',
  嘴: 'シ',
  嘶: 'セイ',
  嘲: 'チョウ',
  嘸: 'ブ',
  噫: 'イ',
  噤: 'キン',
  嘯: 'ショウ',
  噬: 'ゼイ',
  噪: 'ソウ',
  嚆: 'コウ',
  嚀: 'ネイ',
  嚊: 'ヒ',
  嚠: 'リュウ',
  嚔: 'テイ',
  嚏: 'テイ',
  嚥: 'エン',
  嚮: 'コウ',
  嚶: 'オウ',
  嚴: 'ゲン',
  囂: 'ゴウ',
  嚼: 'シャク',
  囁: 'ショウ',
  囃: 'サツ',
  囀: 'テン',
  囈: 'ゲイ',
  囎: 'ソ',
  囑: 'ショク',
  囓: 'ゲツ',
  囗: 'イ',
  囮: 'カ',
  囹: 'レイ',
  圀: 'コク',
  囿: 'ユウ',
  圄: 'ゴ',
  圉: 'ギョ',
  圈: 'ケン',
  國: 'コク',
  圍: 'イ',
  圓: 'エン',
  團: 'ダン',
  圖: 'ズ',
  嗇: 'ショク',
  圜: 'カン',
  圦: 'いり',
  圷: 'あくつ',
  圸: 'まま',
  坎: 'カン',
  圻: 'キ',
  址: 'シ',
  坏: 'ハイ',
  坩: 'カン',
  埀: 'スイ',
  垈: 'タイ',
  坡: 'ハ',
  坿: 'フ',
  垉: 'ホウ',
  垓: 'ガイ',
  垠: 'ギン',
  垳: 'がけ',
  垤: 'テツ',
  垪: 'ハ',
  垰: 'たわ',
  埃: 'アイ',
  埆: 'カク',
  埔: 'ホ',
  埒: 'レツ',
  埓: 'レツ',
  堊: 'アク',
  埖: 'ごみ',
  埣: 'ソツ',
  堋: 'ホウ',
  堙: 'イン',
  堝: 'カ',
  塲: 'ジョウ',
  堡: 'ホ',
  塢: 'オ',
  塋: 'エイ',
  塰: 'あま',
  毀: 'キ',
  塒: 'シ',
  堽: 'コウ',
  塹: 'ザン',
  墅: 'ショ',
  墹: 'まま',
  墟: 'キョ',
  墫: 'シュン',
  墺: 'オウ',
  壞: 'カイ',
  墻: 'ショウ',
  墸: 'チョ',
  墮: 'ダ',
  壅: 'ヨウ',
  壓: 'アツ',
  壑: 'ガク',
  壗: 'まま',
  壙: 'コウ',
  壘: 'ルイ',
  壥: 'テン',
  壜: 'ドン',
  壤: 'ジョウ',
  壟: 'ロウ',
  壯: 'ソウ',
  壺: 'コ',
  壹: 'イチ',
  壻: 'セイ',
  壼: 'コン',
  壽: 'ジュ',
  夂: 'チ',
  夊: 'スイ',
  夐: 'ケイ',
  夛: 'タ',
  梦: 'ム',
  夥: 'カ',
  夬: 'カイ',
  夭: 'ヨウ',
  夲: 'ホン',
  夸: 'コ',
  夾: 'キョウ',
  竒: 'キ',
  奕: 'エキ',
  奐: 'カン',
  奎: 'ケイ',
  奚: 'ケイ',
  奘: 'ジョウ',
  奢: 'シャ',
  奠: 'テン',
  奧: 'オウ',
  奬: 'ショウ',
  奩: 'レン',
  奸: 'カン',
  妁: 'シャク',
  妝: 'ソウ',
  佞: 'ネイ',
  侫: 'ネイ',
  妣: 'ヒ',
  妲: 'ダツ',
  姆: 'ボ',
  姨: 'テイ',
  姜: 'キョウ',
  妍: 'ケン',
  姙: 'ニン',
  姚: 'ヨウ',
  娥: 'ガ',
  娟: 'エン',
  娑: 'シャ',
  娜: 'ダ',
  娉: 'ヘイ',
  娚: 'ナン',
  婀: 'ア',
  婬: 'イン',
  婉: 'エン',
  娵: 'シュ',
  娶: 'シュ',
  婢: 'ヒ',
  婪: 'ラン',
  媚: 'ビ',
  媼: 'オウ',
  媾: 'コウ',
  嫋: 'ジョウ',
  嫂: 'ソウ',
  媽: 'ボ',
  嫣: 'エン',
  嫗: 'ウ',
  嫦: 'コウ',
  嫩: 'ドン',
  嫖: 'ヒョウ',
  嫺: 'カン',
  嫻: 'カン',
  嬌: 'キョウ',
  嬋: 'セン',
  嬖: 'ヘイ',
  嬲: 'ドウ',
  嫐: 'ドウ',
  嬪: 'ヒン',
  嬶: 'かか',
  嬾: 'ラン',
  孃: 'ジョウ',
  孅: 'セン',
  孀: 'ソウ',
  孑: 'ケツ',
  孕: 'ヨウ',
  孚: 'フ',
  孛: 'ハイ',
  孥: 'ド',
  孩: 'ガイ',
  孰: 'ジュク',
  孳: 'ジ',
  孵: 'フ',
  學: 'ガク',
  斈: 'ガク',
  孺: 'ジュ',
  宀: 'ベン',
  它: 'タ',
  宦: 'カン',
  宸: 'シン',
  寃: 'エン',
  寇: 'コウ',
  寉: 'つる',
  寔: 'ショク',
  寐: 'ビ',
  寤: 'ゴ',
  實: 'ジツ',
  寢: 'シン',
  寞: 'バク',
  寥: 'リョウ',
  寫: 'シャ',
  寰: 'カン',
  寶: 'ホウ',
  寳: 'ホウ',
  尅: 'コク',
  將: 'ショウ',
  專: 'セン',
  對: 'タイ',
  尓: 'ジ',
  尠: 'セン',
  尢: 'オウ',
  尨: 'ボウ',
  尸: 'シ',
  尹: 'イン',
  屁: 'ヒ',
  屆: 'カイ',
  屎: 'シ',
  屓: 'キ',
  屐: 'ゲキ',
  屏: 'ヘイ',
  孱: 'セン',
  屬: 'ゾク',
  屮: 'テツ',
  乢: 'ガイ',
  屶: 'なた',
  屹: 'キツ',
  岌: 'キュウ',
  岑: 'シン',
  岔: 'タ',
  妛: 'シ',
  岫: 'コウ',
  岻: 'ジ',
  岶: 'ハク',
  岼: 'ゆり',
  岷: 'ビン',
  峅: 'くら',
  岾: 'やま',
  峇: 'コウ',
  峙: 'ジ',
  峩: 'ガ',
  峽: 'キョウ',
  峺: 'コ',
  峭: 'ショウ',
  嶌: 'トウ',
  峪: 'ヨク',
  崋: 'カ',
  崕: 'ガイ',
  崗: 'コウ',
  嵜: 'キ',
  崟: 'ギン',
  崛: 'クツ',
  崑: 'コン',
  崔: 'ガイ',
  崢: 'ソウ',
  崚: 'リョウ',
  崙: 'ロン',
  崘: 'ロン',
  嵌: 'カン',
  嵒: 'ガン',
  嵎: 'グウ',
  嵋: 'ビ',
  嵬: 'カイ',
  嵳: 'サ',
  嵶: 'たお.やか',
  嶇: 'ク',
  嶄: 'ザン',
  嶂: 'ショウ',
  嶢: 'ギョウ',
  嶝: 'トウ',
  嶬: 'ギ',
  嶮: 'ケン',
  嶽: 'ガク',
  嶐: 'リュ',
  嶷: 'ギョク',
  嶼: 'ショ',
  巉: 'ザン',
  巍: 'ギ',
  巓: 'テン',
  巒: 'ラン',
  巖: 'ガン',
  巛: 'セン',
  巫: 'フ',
  已: 'イ',
  巵: 'シ',
  帋: 'シ',
  帚: 'ソウ',
  帙: 'チツ',
  帑: 'トウ',
  帛: 'ハク',
  帶: 'タイ',
  帷: 'イ',
  幄: 'アク',
  幃: 'イ',
  幀: 'テイ',
  幎: 'ベキ',
  幗: 'カク',
  幔: 'マン',
  幟: 'シ',
  幢: 'トウ',
  幤: 'ヘイ',
  幇: 'ホウ',
  幵: 'ヘイ',
  并: 'ヘイ',
  幺: 'ヨウ',
  麼: 'ビ',
  广: 'ゲン',
  庠: 'ショウ',
  廁: 'シ',
  廂: 'ショウ',
  廈: 'カ',
  廐: 'キュウ',
  廏: 'キュウ',
  廖: 'リョウ',
  廣: 'コウ',
  廝: 'シ',
  廚: 'シュウ',
  廛: 'テン',
  廢: 'ハイ',
  廡: 'ブ',
  廨: 'カイ',
  廩: 'リン',
  廬: 'ロ',
  廱: 'ヨウ',
  廳: 'チョウ',
  廰: 'チョウ',
  廴: 'イン',
  廸: 'テキ',
  廾: 'キョウ',
  弃: 'キ',
  弉: 'ジョウ',
  彝: 'イ',
  彜: 'イ',
  弋: 'ヨク',
  弑: 'シ',
  弖: 'テ',
  弩: 'ド',
  弭: 'ビ',
  弸: 'ホウ',
  彁: 'カ',
  彈: 'ダン',
  彌: 'ミ',
  彎: 'ワン',
  弯: 'ワン',
  彑: 'ケイ',
  彖: 'タン',
  彗: 'スイ',
  彙: 'イ',
  彡: 'サン',
  彭: 'ホウ',
  彳: 'テキ',
  彷: 'ホウ',
  徃: 'オウ',
  徂: 'ソ',
  彿: 'フツ',
  徊: 'カイ',
  很: 'コン',
  徑: 'ケイ',
  徇: 'ジュン',
  從: 'ジュウ',
  徙: 'シ',
  徘: 'ハイ',
  徠: 'ライ',
  徨: 'コウ',
  徭: 'ヨウ',
  徼: 'キョウ',
  忖: 'ソン',
  忻: 'キン',
  忤: 'ゴ',
  忸: 'ジク',
  忱: 'シン',
  忝: 'テン',
  悳: 'トク',
  忿: 'フン',
  怡: 'イ',
  恠: 'カイ',
  怙: 'コ',
  怐: 'ク',
  怩: 'ジ',
  怎: 'シン',
  怱: 'ソウ',
  怛: 'ダツ',
  怕: 'ハク',
  怫: 'フツ',
  怦: 'ホウ',
  怏: 'オウ',
  怺: 'こら.える',
  恚: 'イ',
  恁: 'イン',
  恪: 'カク',
  恷: 'キュウ',
  恟: 'キョウ',
  恊: 'キョウ',
  恆: 'コウ',
  恍: 'コウ',
  恣: 'シ',
  恃: 'ジ',
  恤: 'ジュツ',
  恂: 'ジュン',
  恬: 'テン',
  恫: 'ドウ',
  恙: 'ヨウ',
  悁: 'エン',
  悍: 'カン',
  惧: 'ク',
  悃: 'コン',
  悚: 'ショウ',
  悄: 'ショウ',
  悛: 'シュン',
  悖: 'ハイ',
  悗: 'バン',
  悒: 'ユウ',
  悧: 'リ',
  悋: 'リン',
  惡: 'アク',
  悸: 'キ',
  惠: 'ケイ',
  惓: 'ケン',
  悴: 'スイ',
  忰: 'スイ',
  悽: 'セイ',
  惆: 'チュウ',
  悵: 'チョウ',
  惘: 'ボウ',
  慍: 'ウン',
  愕: 'ガク',
  愆: 'ケン',
  惶: 'コウ',
  惷: 'シュン',
  愀: 'シュウ',
  惴: 'ズイ',
  惺: 'セイ',
  愃: 'ケン',
  愡: 'ソウ',
  惻: 'ソク',
  惱: 'ノウ',
  愍: 'ビン',
  愎: 'フク',
  慇: 'イン',
  愾: 'ガイ',
  愨: 'カク',
  愧: 'キ',
  慊: 'ケン',
  愿: 'ゲン',
  愼: 'シン',
  愬: 'ソ',
  愴: 'ソウ',
  愽: 'タン',
  慂: 'ヨウ',
  慄: 'リツ',
  慳: 'ケン',
  慷: 'コウ',
  慘: 'サン',
  慙: 'ザン',
  慚: 'ザン',
  慫: 'ショウ',
  慴: 'シュウ',
  慯: 'ショウ',
  慥: 'ゾウ',
  慱: 'タン',
  慟: 'ドウ',
  慝: 'トク',
  慓: 'ヒョウ',
  慵: 'ヨウ',
  憙: 'キ',
  憖: 'ギン',
  憇: 'ケイ',
  憬: 'ケイ',
  憔: 'ショウ',
  憚: 'タン',
  憊: 'ハイ',
  憑: 'ヒョウ',
  憫: 'ビン',
  憮: 'ブ',
  懌: 'エキ',
  懊: 'オウ',
  應: 'オウ',
  懷: 'カイ',
  懈: 'ケ',
  懃: 'キン',
  懆: 'ソウ',
  憺: 'タン',
  懋: 'ボウ',
  罹: 'リ',
  懍: 'リン',
  懦: 'ダ',
  懣: 'マン',
  懶: 'ラン',
  懺: 'ザン',
  懴: 'ザン',
  懿: 'イ',
  懽: 'カン',
  懼: 'ク',
  懾: 'ショウ',
  戀: 'レン',
  戈: 'カ',
  戉: 'ジュウ',
  戍: 'ジュ',
  戌: 'ジュツ',
  戔: 'セン',
  戛: 'カツ',
  戞: 'カツ',
  戡: 'カン',
  截: 'セツ',
  戮: 'リク',
  戰: 'セン',
  戲: 'ギ',
  戳: 'タク',
  扁: 'ヘン',
  扎: 'サツ',
  扞: 'カン',
  扣: 'コウ',
  扛: 'コウ',
  扠: 'サ',
  扨: 'サ',
  扼: 'ヤク',
  抂: 'キョウ',
  抉: 'ケツ',
  找: 'ソウ',
  抒: 'ジョ',
  抓: 'ソウ',
  抖: 'ト',
  拔: 'バツ',
  抃: 'ベン',
  抔: 'ホウ',
  拗: 'オウ',
  拑: 'カン',
  抻: 'シン',
  拏: 'ダ',
  拿: 'ダ',
  拆: 'タク',
  擔: 'タン',
  拈: 'ネン',
  拜: 'ハイ',
  拌: 'ハン',
  拊: 'フ',
  拂: 'ヒツ',
  拇: 'ボ',
  抛: 'ホウ',
  拉: 'ラツ',
  挌: 'カク',
  拮: 'カツ',
  拱: 'キョウ',
  挧: 'ウ',
  挂: 'ケイ',
  挈: 'ケイ',
  拯: 'ショウ',
  拵: 'ソン',
  捐: 'エン',
  挾: 'キョウ',
  捍: 'カン',
  搜: 'ソウ',
  捏: 'ネツ',
  掖: 'エキ',
  掎: 'キ',
  掀: 'キン',
  掫: 'ソウ',
  捶: 'スイ',
  掣: 'セイ',
  掏: 'トウ',
  掉: 'トウ',
  掟: 'トウ',
  掵: 'ハバ',
  捫: 'モン',
  捩: 'レイ',
  掾: 'エン',
  揩: 'カイ',
  揀: 'カン',
  揆: 'キ',
  揣: 'シ',
  揉: 'ジュウ',
  插: 'ソウ',
  揶: 'ヤ',
  揄: 'ヨウ',
  搖: 'ヨウ',
  搴: 'ケン',
  搆: 'コウ',
  搓: 'サ',
  搦: 'ジャク',
  搶: 'ソウ',
  攝: 'セツ',
  搗: 'トウ',
  搨: 'トウ',
  搏: 'ハク',
  摧: 'サイ',
  摯: 'シ',
  摶: 'タン',
  摎: 'ク',
  攪: 'カク',
  撕: 'セイ',
  撓: 'トウ',
  撥: 'ハツ',
  撩: 'リョウ',
  撈: 'ロウ',
  撼: 'カン',
  據: 'キョ',
  擒: 'キン',
  擅: 'セン',
  擇: 'タク',
  撻: 'タツ',
  擘: 'ハク',
  擂: 'ライ',
  擱: 'カク',
  擧: 'キョ',
  舉: 'キョ',
  擠: 'セイ',
  擡: 'タイ',
  抬: 'タイ',
  擣: 'トウ',
  擯: 'ヒン',
  攬: 'ラン',
  擶: 'セン',
  擴: 'カク',
  擲: 'テキ',
  擺: 'ハイ',
  攀: 'ハン',
  擽: 'リャク',
  攘: 'ジョウ',
  攜: 'ケイ',
  攅: 'サン',
  攤: 'タン',
  攣: 'レン',
  攫: 'カク',
  攴: 'ホク',
  攵: 'ホク',
  攷: 'コウ',
  收: 'シュウ',
  攸: 'ユウ',
  畋: 'デン',
  效: 'コウ',
  敖: 'ゴウ',
  敕: 'チョク',
  敍: 'ジョ',
  敘: 'ジョ',
  敞: 'ショウ',
  敝: 'ヘイ',
  敲: 'コウ',
  數: 'スウ',
  斂: 'レン',
  斃: 'ヘイ',
  變: 'ヘン',
  斛: 'コク',
  斟: 'シン',
  斫: 'シャク',
  斷: 'ダン',
  旃: 'セン',
  旆: 'ハイ',
  旁: 'ボウ',
  旄: 'ボウ',
  旌: 'セイ',
  旒: 'リュウ',
  旛: 'ヘン',
  旙: 'ヘン',
  无: 'ブ',
  旡: 'キ',
  旱: 'カン',
  杲: 'コウ',
  昊: 'コウ',
  昃: 'ショク',
  旻: 'ビン',
  杳: 'ヨウ',
  昵: 'ジツ',
  昶: 'チョウ',
  昴: 'コウ',
  昜: 'ヨウ',
  晏: 'アン',
  晄: 'コウ',
  晉: 'シン',
  晁: 'チョウ',
  晞: 'キ',
  晝: 'チュウ',
  晤: 'ゴ',
  晧: 'コウ',
  晨: 'シン',
  晟: 'セイ',
  晢: 'セイ',
  晰: 'シャク',
  暃: 'ヒ',
  暈: 'ウン',
  暎: 'エイ',
  暉: 'キ',
  暄: 'ケン',
  暘: 'ヨウ',
  暝: 'メイ',
  曁: 'キ',
  暹: 'セン',
  曉: 'キョウ',
  暾: 'トン',
  暼: 'ヘツ',
  曄: 'ヨウ',
  暸: 'リョウ',
  曖: 'アイ',
  曚: 'モウ',
  曠: 'コウ',
  昿: 'コウ',
  曦: 'ギ',
  曩: 'ノウ',
  曰: 'エツ',
  曵: 'エイ',
  曷: 'カツ',
  朏: 'ヒ',
  朖: 'ロウ',
  朞: 'キ',
  朦: 'モウ',
  朧: 'ロウ',
  霸: 'ハ',
  朮: 'ジュツ',
  朿: 'シ',
  朶: 'ダ',
  杁: 'いり',
  朸: 'リョク',
  朷: 'トウ',
  杆: 'カン',
  杞: 'コ',
  杠: 'コウ',
  杙: 'ヨク',
  杣: 'そま',
  杤: 'とち',
  枉: 'オウ',
  杰: 'ケツ',
  枩: 'ショウ',
  杼: 'チョ',
  杪: 'ビョウ',
  枌: 'ショウ',
  枋: 'ホウ',
  枦: 'ロ',
  枡: 'ます',
  枅: 'ケイ',
  枷: 'カ',
  柯: 'カ',
  枴: 'カイ',
  柬: 'カン',
  枳: 'キ',
  柩: 'キュウ',
  枸: 'ク',
  柤: 'サ',
  柞: 'サク',
  柝: 'タク',
  柢: 'テイ',
  柮: 'トツ',
  枹: 'フ',
  柎: 'フ',
  柆: 'ロウ',
  柧: 'コ',
  檜: 'カイ',
  栞: 'カン',
  框: 'キョウ',
  栩: 'ク',
  桀: 'ケツ',
  桍: 'コ',
  栲: 'コウ',
  桎: 'シツ',
  梳: 'ソ',
  栫: 'セン',
  桙: 'ウ',
  档: 'トウ',
  桷: 'カク',
  桿: 'カン',
  梟: 'キョウ',
  梏: 'コク',
  梭: 'サ',
  梔: 'シ',
  條: 'ジョウ',
  梛: 'ダ',
  梃: 'チョウ',
  檮: 'トウ',
  梹: 'ヒン',
  桴: 'フ',
  梵: 'ボン',
  梠: 'ロ',
  梺: 'ふむと',
  椏: 'また',
  梍: 'ユウ',
  桾: 'クン',
  椁: 'カク',
  棊: 'キ',
  椈: 'キク',
  棘: 'キョク',
  椢: 'カイ',
  椦: 'ケン',
  棡: 'コウ',
  椌: 'コウ',
  棍: 'コン',
  棔: 'コン',
  棧: 'サン',
  棕: 'シュ',
  椶: 'シュ',
  椒: 'ショウ',
  椄: 'セツ',
  棗: 'ソウ',
  棣: 'テイ',
  椥: 'なぎ',
  棹: 'トウ',
  棠: 'トウ',
  棯: 'ネン',
  椨: 'たぶ',
  椪: 'ポン',
  椚: 'くぬぎ',
  椣: 'しで',
  椡: 'くぬぎ',
  棆: 'リン',
  楹: 'エイ',
  楷: 'カイ',
  楜: 'コ',
  楸: 'シュウ',
  楫: 'シュウ',
  楔: 'ケツ',
  楾: 'はんぞう',
  楮: 'チョ',
  椹: 'ジン',
  楴: 'テイ',
  椽: 'テン',
  楙: 'ボウ',
  椰: 'ヤ',
  楡: 'ユ',
  楞: 'リョウ',
  楝: 'レン',
  榁: 'むろ',
  楪: 'チャ',
  榲: 'オツ',
  榮: 'エイ',
  槐: 'カイ',
  榿: 'ガイ',
  槁: 'コウ',
  槓: 'コウ',
  榾: 'コツ',
  槎: 'サ',
  寨: 'サイ',
  槊: 'サク',
  槝: 'かし',
  榻: 'トウ',
  槃: 'ハン',
  榧: 'ヒ',
  樮: 'ほくそ',
  榑: 'フ',
  榠: 'ベイ',
  榜: 'ボウ',
  榕: 'ヨウ',
  榴: 'ル',
  槞: 'ロウ',
  槨: 'カク',
  樂: 'ガク',
  樛: 'キュウ',
  槿: 'キン',
  權: 'ケン',
  槹: 'コウ',
  槲: 'コク',
  槧: 'ザン',
  樅: 'ショウ',
  榱: 'スイ',
  樞: 'スウ',
  槭: 'セキ',
  樔: 'ソウ',
  槫: 'タン',
  樊: 'ハン',
  樒: 'ミツ',
  櫁: 'ミツ',
  樣: 'ヨウ',
  樓: 'ロウ',
  橄: 'カン',
  樌: 'カン',
  橲: 'キ',
  樶: 'サイ',
  橸: 'まさ',
  橇: 'キョウ',
  橢: 'ダ',
  橙: 'トウ',
  橦: 'シュ',
  橈: 'ドウ',
  樸: 'ボク',
  樢: 'ト',
  檐: 'エン',
  檍: 'オク',
  檠: 'ケイ',
  檄: 'ケキ',
  檢: 'ケン',
  檣: 'ショウ',
  檗: 'ハク',
  蘗: 'ハク',
  檻: 'カン',
  櫃: 'キ',
  櫂: 'トウ',
  檸: 'ネイ',
  檳: 'ビン',
  檬: 'モウ',
  櫞: 'エン',
  櫑: 'ライ',
  櫟: 'レキ',
  檪: 'レキ',
  櫚: 'ロ',
  櫪: 'レキ',
  櫻: 'オウ',
  欅: 'キョ',
  蘖: 'ゲツ',
  櫺: 'レイ',
  欒: 'ラン',
  欖: 'ラン',
  鬱: 'ウツ',
  欟: 'つき',
  欸: 'アイ',
  欷: 'キ',
  盜: 'トウ',
  欹: 'イ',
  飮: 'イン',
  歇: 'ケツ',
  歃: 'ソウ',
  歉: 'ケン',
  歐: 'オウ',
  歙: 'キュウ',
  歔: 'キョ',
  歛: 'レン',
  歟: 'ヨ',
  歡: 'カン',
  歸: 'キ',
  歹: 'ガツ',
  歿: 'ボツ',
  殀: 'ヨウ',
  殄: 'テン',
  殃: 'オウ',
  殍: 'ヒョウ',
  殘: 'ザン',
  殕: 'フ',
  殞: 'イン',
  殤: 'ショウ',
  殪: 'エイ',
  殫: 'タン',
  殯: 'ヒン',
  殲: 'セン',
  殱: 'セン',
  殳: 'シュ',
  殷: 'イン',
  殼: 'カク',
  毆: 'オウ',
  毋: 'ブ',
  毓: 'イク',
  毟: 'むし.る',
  毬: 'キュウ',
  毫: 'ゴウ',
  毳: 'ゼイ',
  毯: 'タン',
  麾: 'キ',
  氈: 'セン',
  氓: 'ボウ',
  气: 'キ',
  氛: 'フン',
  氤: 'イン',
  氣: 'キ',
  汞: 'コウ',
  汕: 'サン',
  汢: 'ねた',
  汪: 'オウ',
  沂: 'ギ',
  沍: 'ゴ',
  沚: 'シ',
  沁: 'シン',
  沛: 'ハイ',
  汾: 'フン',
  汨: 'コツ',
  汳: 'ヘン',
  沒: 'ボツ',
  沐: 'モク',
  泄: 'セツ',
  泱: 'オウ',
  泓: 'オウ',
  沽: 'コ',
  泗: 'シ',
  泅: 'シュウ',
  泝: 'ソ',
  沮: 'ソ',
  沱: 'タ',
  沾: 'セン',
  沺: 'デン',
  泛: 'ハン',
  泯: 'ビン',
  泙: 'ホウ',
  泪: 'ルイ',
  洟: 'イ',
  衍: 'エン',
  洶: 'キョウ',
  洫: 'キョク',
  洽: 'コウ',
  洸: 'コウ',
  洙: 'シュ',
  洵: 'ジュン',
  洳: 'ジュ',
  洒: 'シャ',
  洌: 'レツ',
  浣: 'カン',
  涓: 'ケン',
  浤: 'コウ',
  浚: 'シュン',
  浹: 'ショウ',
  浙: 'セツ',
  涎: 'セン',
  涕: 'テイ',
  濤: 'トウ',
  涅: 'ネツ',
  淹: 'エン',
  渕: 'エン',
  渊: 'エン',
  涵: 'カン',
  淇: 'キ',
  淦: 'カン',
  涸: 'コ',
  淆: 'コウ',
  淬: 'サイ',
  淞: 'ショウ',
  淌: 'トウ',
  淨: 'ジョウ',
  淒: 'セイ',
  淅: 'セキ',
  淺: 'セン',
  淙: 'ソウ',
  淤: 'オ',
  淕: 'リク',
  淪: 'リン',
  淮: 'ワイ',
  渭: 'イ',
  湮: 'イン',
  渮: 'カ',
  渙: 'カン',
  湲: 'エン',
  湟: 'コウ',
  渾: 'コン',
  渣: 'サ',
  湫: 'シュウ',
  渫: 'セツ',
  湶: 'セン',
  湍: 'タン',
  渟: 'テイ',
  湃: 'ハイ',
  渺: 'ビョウ',
  湎: 'メン',
  渤: 'ボツ',
  滿: 'マン',
  渝: 'ユ',
  游: 'ユウ',
  溂: 'ラツ',
  溪: 'ケイ',
  溘: 'コウ',
  滉: 'コウ',
  溷: 'コン',
  滓: 'シ',
  溽: 'ヒョク',
  溯: 'ソ',
  滄: 'ソウ',
  溲: 'シュ',
  滔: 'トウ',
  滕: 'トウ',
  溏: 'トウ',
  溥: 'フ',
  滂: 'ホウ',
  溟: 'メイ',
  潁: 'エイ',
  漑: 'ガイ',
  灌: 'カン',
  滬: 'コウ',
  滸: 'コ',
  滾: 'コン',
  漿: 'ショウ',
  滲: 'シン',
  漱: 'ソウ',
  滯: 'タイ',
  漲: 'チョウ',
  滌: 'デキ',
  漾: 'ヨウ',
  漓: 'リ',
  滷: 'ロ',
  澆: 'ギョウ',
  潺: 'セン',
  潸: 'サン',
  澁: 'ジュウ',
  澀: 'ジュウ',
  潯: 'ジン',
  潛: 'セン',
  濳: 'セン',
  潭: 'タン',
  澂: 'チョウ',
  潼: 'ドウ',
  潘: 'ハン',
  澎: 'ホウ',
  澑: 'リュウ',
  濂: 'レン',
  潦: 'ロウ',
  澳: 'オウ',
  澣: 'カン',
  澡: 'ソウ',
  澤: 'タク',
  澹: 'タン',
  濆: 'フン',
  澪: 'レイ',
  濟: 'サイ',
  濕: 'シツ',
  濬: 'シュン',
  濔: 'ビ',
  濘: 'ネイ',
  濱: 'ヒン',
  濮: 'ボク',
  濛: 'モウ',
  瀉: 'シャ',
  瀋: 'シン',
  濺: 'セン',
  瀑: 'バク',
  瀁: 'ヨウ',
  瀏: 'リュウ',
  濾: 'ロ',
  瀛: 'エイ',
  瀚: 'カン',
  潴: 'チョ',
  瀝: 'レキ',
  瀘: 'ロ',
  瀟: 'ショウ',
  瀰: 'ビ',
  瀾: 'ラン',
  瀲: 'レン',
  灑: 'シャ',
  灣: 'ワン',
  炙: 'シャ',
  炒: 'ソウ',
  炯: 'ケイ',
  烱: 'ケイ',
  炬: 'コ',
  炸: 'タク',
  炳: 'ヘイ',
  炮: 'ホウ',
  烟: 'エン',
  烋: 'コウ',
  烝: 'ジョウ',
  烙: 'ラク',
  焉: 'エン',
  烽: 'ホウ',
  焜: 'コン',
  焙: 'ホウ',
  煥: 'カン',
  煕: 'キ',
  熈: 'キ',
  煦: 'ク',
  煢: 'ケイ',
  煌: 'コウ',
  煖: 'ダン',
  煬: 'ヨウ',
  熏: 'クン',
  燻: 'クン',
  熄: 'ソク',
  熕: 'おおづつ',
  熨: 'イ',
  熬: 'ゴウ',
  燗: 'ラン',
  熹: 'キ',
  熾: 'シ',
  燒: 'ショウ',
  燉: 'トン',
  燔: 'ハン',
  燎: 'リョウ',
  燠: 'ウ',
  燬: 'キ',
  燧: 'スイ',
  燵: 'タツ',
  燼: 'ジン',
  燹: 'セン',
  燿: 'ヨウ',
  爍: 'シャク',
  爐: 'ロ',
  爛: 'ラン',
  爨: 'サン',
  爭: 'ソウ',
  爬: 'ハ',
  爰: 'エン',
  爲: 'イ',
  爻: 'コウ',
  爼: 'ソ',
  爿: 'ショウ',
  牀: 'ショウ',
  牆: 'ショウ',
  牋: 'セン',
  牘: 'トク',
  牴: 'テイ',
  牾: 'ゴ',
  犂: 'リ',
  犁: 'リ',
  犇: 'ホン',
  犒: 'コウ',
  犖: 'ラク',
  犢: 'トク',
  犧: 'ギ',
  犹: 'ジョウ',
  犲: 'サイ',
  狃: 'ジュウ',
  狆: 'チュウ',
  狄: 'テキ',
  狎: 'オウ',
  狒: 'ヒ',
  狢: 'カク',
  狠: 'ガン',
  狡: 'コウ',
  狹: 'キョウ',
  狷: 'ケン',
  倏: 'シュク',
  猗: 'イ',
  猊: 'ゲイ',
  猜: 'サイ',
  猖: 'ショウ',
  猝: 'ソツ',
  猴: 'コウ',
  猯: 'タン',
  猩: 'ショウ',
  猥: 'ワイ',
  猾: 'カツ',
  獎: 'ショウ',
  獏: 'バク',
  默: 'ボク',
  獗: 'ケツ',
  獪: 'カイ',
  獨: 'ドク',
  獰: 'ドウ',
  獸: 'ジュウ',
  獵: 'リョウ',
  獻: 'ケン',
  獺: 'ダツ',
  珈: 'カ',
  玳: 'タイ',
  珎: 'チン',
  玻: 'ハ',
  珀: 'ハク',
  珥: 'ジ',
  珮: 'ハイ',
  珞: 'ラク',
  璢: 'ル',
  琅: 'ロウ',
  瑯: 'ロウ',
  琥: 'コ',
  珸: 'ゴ',
  琲: 'ハイ',
  琺: 'ホウ',
  瑕: 'カ',
  琿: 'コン',
  瑟: 'シツ',
  瑙: 'ノウ',
  瑁: 'マイ',
  瑜: 'ユ',
  瑩: 'エイ',
  瑰: 'カイ',
  瑣: 'サ',
  瑪: 'メ',
  瑶: 'ヨウ',
  瑾: 'キン',
  璋: 'ショウ',
  璞: 'ハク',
  璧: 'ヘキ',
  瓊: 'ケイ',
  瓏: 'ロウ',
  瓔: 'エイ',
  珱: 'エイ',
  瓠: 'コ',
  瓣: 'ベン',
  瓧: 'デカグラム',
  瓩: 'キログラム',
  瓮: 'オウ',
  瓲: 'トン',
  瓰: 'デシグラム',
  瓱: 'ミリグラム',
  瓸: 'ヘクトグラム',
  瓷: 'シ',
  甄: 'ケン',
  甃: 'シュウ',
  甅: 'センチグラム',
  甌: 'オウ',
  甎: 'セン',
  甍: 'ボウ',
  甕: 'オウ',
  甓: 'ヘキ',
  甞: 'ショウ',
  甦: 'ソ',
  甬: 'ヨウ',
  甼: 'チョウ',
  畄: 'リュウ',
  畍: 'カイ',
  畊: 'キョウ',
  畉: 'フ',
  畛: 'シン',
  畆: 'ボウ',
  畚: 'ホン',
  畩: 'けさ',
  畤: 'ジ',
  畧: 'リャク',
  畫: 'ガ',
  畭: 'ヨ',
  畸: 'キ',
  當: 'トウ',
  疆: 'キョウ',
  疇: 'チュウ',
  畴: 'チュウ',
  疊: 'ジョウ',
  疉: 'ジョウ',
  疂: 'ジョウ',
  疔: 'テイ',
  疚: 'キュウ',
  疝: 'セン',
  疥: 'カイ',
  疣: 'ユ',
  痂: 'カ',
  疳: 'カン',
  痃: 'ゲン',
  疵: 'シ',
  疽: 'ソ',
  疸: 'タン',
  疼: 'トウ',
  疱: 'ホウ',
  痍: 'イ',
  痊: 'セン',
  痒: 'ヨウ',
  痙: 'ケイ',
  痣: 'シ',
  痞: 'ヒ',
  痾: 'ア',
  痿: 'イ',
  痼: 'コ',
  瘁: 'スイ',
  痰: 'タン',
  痺: 'ヒ',
  痲: 'マ',
  痳: 'リン',
  瘋: 'フウ',
  瘍: 'ヨウ',
  瘉: 'ユ',
  瘟: 'オン',
  瘧: 'ギャク',
  瘠: 'セキ',
  瘡: 'ソウ',
  瘢: 'ハン',
  瘤: 'リュウ',
  瘴: 'ショウ',
  瘰: 'ルイ',
  瘻: 'ル',
  癇: 'カン',
  癈: 'ハイ',
  癆: 'ロウ',
  癜: 'デン',
  癘: 'レイ',
  癡: 'チ',
  癢: 'ヨウ',
  癨: 'カク',
  癩: 'ライ',
  癪: 'しゃく',
  癧: 'レキ',
  癬: 'セン',
  癰: 'ヨウ',
  癲: 'テン',
  癶: 'ハツ',
  癸: 'キ',
  發: 'ハツ',
  皀: 'キュウ',
  皃: 'ボウ',
  皈: 'キ',
  皋: 'コウ',
  皎: 'キョウ',
  皖: 'カン',
  皓: 'コウ',
  皙: 'シャク',
  皚: 'ガイ',
  皰: 'ホウ',
  皴: 'シュン',
  皸: 'クン',
  皹: 'クン',
  皺: 'シュウ',
  盂: 'ウ',
  盍: 'コウ',
  盖: 'ガイ',
  盒: 'コウ',
  盞: 'サン',
  盡: 'ジン',
  盥: 'カン',
  盧: 'ロ',
  盪: 'トウ',
  蘯: 'トウ',
  盻: 'ケイ',
  眈: 'タン',
  眇: 'ビョウ',
  眄: 'ベン',
  眩: 'ゲン',
  眤: 'ケイ',
  眞: 'シン',
  眥: 'セ',
  眦: 'セ',
  眛: 'マイ',
  眷: 'ケン',
  眸: 'ボウ',
  睇: 'テイ',
  睚: 'ガイ',
  睨: 'ゲイ',
  睫: 'ショウ',
  睛: 'セイ',
  睥: 'ヘイ',
  睿: 'エイ',
  睾: 'コウ',
  睹: 'ト',
  瞎: 'カツ',
  瞋: 'シン',
  瞑: 'メイ',
  瞠: 'ドウ',
  瞞: 'マン',
  瞰: 'カン',
  瞶: 'キ',
  瞹: 'アイ',
  瞿: 'ク',
  瞼: 'ケン',
  瞽: 'コ',
  瞻: 'セン',
  矇: 'モウ',
  矍: 'カク',
  矗: 'チョク',
  矚: 'ショク',
  矜: 'キン',
  矣: 'イ',
  矮: 'ワイ',
  矼: 'コウ',
  砌: 'サイ',
  砒: 'ヒ',
  礦: 'コウ',
  砠: 'ソ',
  礪: 'レイ',
  硅: 'カク',
  碎: 'サイ',
  硴: 'かき',
  碆: 'ハ',
  硼: 'ホウ',
  碚: 'ハイ',
  碌: 'ロク',
  碣: 'ケツ',
  碵: 'せき',
  碪: 'チン',
  碯: 'ノウ',
  磑: 'ガイ',
  磆: 'カツ',
  磋: 'サ',
  磔: 'タク',
  碾: 'テン',
  碼: 'バ',
  磅: 'ボウ',
  磊: 'ライ',
  磬: 'ケイ',
  磧: 'セキ',
  磚: 'セン',
  磽: 'コウ',
  磴: 'トウ',
  礇: 'イク',
  礒: 'ギ',
  礑: 'トウ',
  礙: 'ガイ',
  礬: 'バン',
  礫: 'レキ',
  祀: 'シ',
  祠: 'シ',
  祗: 'シ',
  祟: 'スイ',
  祚: 'ソ',
  祕: 'ヒ',
  祓: 'フツ',
  祺: 'キ',
  祿: 'ロク',
  禊: 'ケイ',
  禝: 'ショク',
  禧: 'キ',
  齋: 'サイ',
  禪: 'ゼン',
  禮: 'レイ',
  禳: 'ジョウ',
  禹: 'ウ',
  禺: 'グ',
  秉: 'ヘイ',
  秕: 'ヒ',
  秧: 'オウ',
  秬: 'キョ',
  秡: 'ハツ',
  秣: 'マツ',
  稈: 'カン',
  稍: 'ソウ',
  稘: 'キ',
  稙: 'ショク',
  稠: 'チュウ',
  稟: 'リン',
  禀: 'リン',
  稱: 'ショウ',
  稻: 'トウ',
  稾: 'コウ',
  稷: 'ショク',
  穃: 'ヨウ',
  穗: 'スイ',
  穉: 'チ',
  穡: 'ショク',
  穢: 'アイ',
  穩: 'オン',
  龝: 'シュウ',
  穰: 'ジョウ',
  穹: 'キュウ',
  穽: 'セイ',
  窈: 'ヨウ',
  窗: 'ソウ',
  窕: 'チョウ',
  窘: 'キン',
  窖: 'コウ',
  窩: 'カ',
  竈: 'ソウ',
  窰: 'ヨウ',
  窶: 'ロウ',
  竅: 'キョウ',
  竄: 'ザン',
  窿: 'リュウ',
  邃: 'スイ',
  竇: 'トウ',
  竊: 'セツ',
  竍: 'デカリットル',
  竏: 'キロリットル',
  竕: 'デシリットル',
  竓: 'ミリリットル',
  站: 'タン',
  竚: 'チョ',
  竝: 'ヘイ',
  竡: 'ヘクトリットル',
  竢: 'シ',
  竦: 'ショウ',
  竭: 'ケツ',
  竰: 'センチリットル',
  笂: 'うつぼ',
  笏: 'コツ',
  笊: 'ソウ',
  笆: 'ハ',
  笳: 'カ',
  笘: 'セン',
  笙: 'ショウ',
  笞: 'チ',
  笵: 'ハン',
  笨: 'ホン',
  笶: 'シ',
  筐: 'キョウ',
  筺: 'キョウ',
  笄: 'ケイ',
  筍: 'ジュン',
  笋: 'ジュン',
  筌: 'セン',
  筅: 'セン',
  筵: 'エン',
  筥: 'キョ',
  筴: 'キョウ',
  筧: 'ケン',
  筰: 'サク',
  筱: 'ゾウ',
  筬: 'セイ',
  筮: 'ゼイ',
  箝: 'カン',
  箘: 'キン',
  箟: 'キン',
  箍: 'コ',
  箜: 'ク',
  箚: 'トウ',
  箋: 'セン',
  箒: 'ソウ',
  箏: 'ソウ',
  筝: 'ソウ',
  箙: 'フク',
  篋: 'キョウ',
  篁: 'コウ',
  篌: 'ゴ',
  篏: 'カン',
  箴: 'シン',
  篆: 'テン',
  篝: 'コウ',
  篩: 'シ',
  簑: 'サ',
  簔: 'サ',
  篦: 'ヘイ',
  篥: 'リキ',
  籠: 'ロウ',
  簀: 'サク',
  簇: 'ゾク',
  簓: 'ささら',
  篳: 'ヒチ',
  篷: 'ホウ',
  簗: 'リョウ',
  簍: 'ル',
  篶: 'エン',
  簣: 'キ',
  簧: 'コウ',
  簪: 'シン',
  簟: 'タン',
  簷: 'エン',
  簫: 'ショウ',
  簽: 'セン',
  籌: 'チュウ',
  籃: 'ラン',
  籔: 'ソウ',
  籏: 'キ',
  籀: 'チュウ',
  籐: 'トウ',
  籘: 'トウ',
  籟: 'ライ',
  籤: 'セン',
  籖: 'セン',
  籥: 'ヤク',
  籬: 'リ',
  籵: 'デカメートル',
  粃: 'ヒ',
  粐: 'ロ',
  粤: 'エツ',
  粭: 'すくも',
  粢: 'シ',
  粫: 'ジ',
  粡: 'トウ',
  粨: 'ヘクトメートル',
  粳: 'コウ',
  粲: 'サン',
  粱: 'リョウ',
  粮: 'リョウ',
  粹: 'スイ',
  粽: 'ソウ',
  糀: 'こうじ',
  糅: 'ジュウ',
  糂: 'ジン',
  糘: 'すくも',
  糒: 'ビ',
  糜: 'ビ',
  糢: 'ボ',
  鬻: 'イク',
  糯: 'ダ',
  糲: 'レイ',
  糴: 'チョウ',
  糶: 'チョウ',
  糺: 'キュウ',
  紆: 'ウ',
  紂: 'チュウ',
  紜: 'ウン',
  紕: 'ヒ',
  紊: 'ビン',
  絅: 'ケイ',
  絋: 'コウ',
  紮: 'サツ',
  紲: 'セツ',
  紿: 'タイ',
  紵: 'チョ',
  絆: 'ハン',
  絳: 'コウ',
  絖: 'コウ',
  絎: 'コウ',
  絲: 'シ',
  絨: 'ジュウ',
  絮: 'ジョ',
  絏: 'セツ',
  絣: 'ホウ',
  經: 'ケイ',
  綉: 'シュウ',
  絛: 'ジュウ',
  綏: 'スイ',
  絽: 'ロ',
  綛: 'かせ',
  綺: 'キ',
  綮: 'ケイ',
  綣: 'ケン',
  綵: 'サイ',
  緇: 'シ',
  綽: 'シャク',
  綫: 'セン',
  總: 'ソウ',
  綢: 'チュウ',
  綯: 'トウ',
  緜: 'メン',
  綸: 'リン',
  綟: 'レイ',
  綰: 'ワン',
  緘: 'カン',
  緝: 'シュウ',
  緤: 'セツ',
  緞: 'ドン',
  緻: 'チ',
  緲: 'ビョウ',
  緡: 'ビン',
  縅: 'おど.す',
  縊: 'イ',
  縣: 'ケン',
  縡: 'サイ',
  縒: 'シ',
  縱: 'ジュウ',
  縟: 'ジョク',
  縉: 'シン',
  縋: 'ツイ',
  縢: 'トウ',
  繆: 'ビュウ',
  繦: 'キョウ',
  縻: 'ビ',
  縵: 'マン',
  縹: 'ヒョウ',
  繃: 'ホウ',
  縷: 'ル',
  縲: 'ルイ',
  縺: 'レン',
  繧: 'ウン',
  繝: 'カン',
  繖: 'サン',
  繞: 'ジョウ',
  繙: 'ハン',
  繚: 'リョウ',
  繹: 'エキ',
  繪: 'カイ',
  繩: 'ジョウ',
  繼: 'ケイ',
  繻: 'シュ',
  纃: 'かすり',
  緕: 'かすり',
  繽: 'ヒン',
  辮: 'ベン',
  繿: 'ラン',
  纈: 'ケツ',
  纉: 'サン',
  續: 'ゾク',
  纒: 'テン',
  纐: 'コウ',
  纓: 'エイ',
  纔: 'サン',
  纖: 'セン',
  纎: 'セン',
  纛: 'トウ',
  纜: 'ラン',
  缸: 'コウ',
  缺: 'ケツ',
  罅: 'カ',
  罌: 'オウ',
  罍: 'ライ',
  罎: 'ドン',
  罐: 'カン',
  网: 'モウ',
  罕: 'カン',
  罔: 'モウ',
  罘: 'フ',
  罟: 'コ',
  罠: 'ビン',
  罨: 'アン',
  罩: 'トウ',
  罧: 'リン',
  罸: 'バツ',
  羂: 'ケン',
  羆: 'ヒ',
  羃: 'ベキ',
  羈: 'キ',
  羇: 'キ',
  羌: 'キョウ',
  羔: 'コウ',
  羞: 'シュウ',
  羝: 'ラン',
  羚: 'レイ',
  羣: 'グン',
  羯: 'カツ',
  羲: 'ギ',
  羹: 'コウ',
  羮: 'コウ',
  羶: 'セン',
  羸: 'ルイ',
  譱: 'ゼン',
  翅: 'シ',
  翆: 'スイ',
  翊: 'ヨク',
  翕: 'キュウ',
  翔: 'ショウ',
  翡: 'ヒ',
  翦: 'セン',
  翩: 'ヘン',
  翳: 'エイ',
  翹: 'ギョウ',
  飜: 'ハン',
  耆: 'キ',
  耄: 'ボウ',
  耋: 'テツ',
  耒: 'ライ',
  耘: 'ウン',
  耙: 'ハ',
  耜: 'シ',
  耡: 'ジョ',
  耨: 'ドウ',
  耿: 'コウ',
  耻: 'チ',
  聊: 'リョウ',
  聆: 'レイ',
  聒: 'カツ',
  聘: 'ヘイ',
  聚: 'シュウ',
  聟: 'セイ',
  聢: 'しか',
  聨: 'レン',
  聳: 'ショウ',
  聲: 'セイ',
  聰: 'ソウ',
  聶: 'ショウ',
  聹: 'ネイ',
  聽: 'チョウ',
  聿: 'イチ',
  肄: 'イ',
  肆: 'シ',
  肅: 'シュク',
  肛: 'コウ',
  肓: 'コウ',
  肚: 'ト',
  肭: 'トツ',
  冐: 'ボウ',
  肬: 'ユ',
  胛: 'コウ',
  胥: 'ショ',
  胙: 'ソ',
  胝: 'チ',
  胄: 'チュウ',
  胚: 'ハイ',
  胖: 'ハン',
  脉: 'ミャク',
  胯: 'コウ',
  胱: 'コウ',
  脛: 'ケイ',
  脩: 'シュウ',
  脣: 'シン',
  脯: 'フ',
  腋: 'エキ',
  隋: 'ズイ',
  腆: 'テン',
  脾: 'ヒ',
  腓: 'ヒ',
  腑: 'フ',
  胼: 'ヘン',
  腱: 'ケン',
  腮: 'サイ',
  腥: 'セイ',
  腦: 'ノウ',
  腴: 'ユ',
  膃: 'オツ',
  膈: 'カク',
  膊: 'ハク',
  膀: 'ボウ',
  膂: 'リョ',
  膠: 'コウ',
  膕: 'カク',
  膤: 'セツ',
  膣: 'チツ',
  腟: 'チツ',
  膓: 'チョウ',
  膩: 'ジ',
  膰: 'ハン',
  膵: 'スイ',
  膾: 'カイ',
  膸: 'ズイ',
  膽: 'タン',
  臀: 'デン',
  臂: 'ヒ',
  膺: 'ヨウ',
  臉: 'ケン',
  臍: 'セイ',
  臑: 'ジ',
  臙: 'エン',
  臘: 'ロウ',
  臈: 'ロウ',
  臚: 'ロ',
  臟: 'ゾウ',
  臠: 'レン',
  臧: 'ゾウ',
  臺: 'ダイ',
  臻: 'シン',
  臾: 'ユ',
  舁: 'ヨ',
  舂: 'ショウ',
  舅: 'キュウ',
  與: 'ヨ',
  舊: 'キュウ',
  舍: 'セキ',
  舐: 'シ',
  舖: 'ホ',
  舩: 'セン',
  舫: 'ホウ',
  舸: 'カ',
  舳: 'ジク',
  艀: 'フ',
  艙: 'ソウ',
  艘: 'ショウ',
  艝: 'そり',
  艚: 'ソウ',
  艟: 'ドウ',
  艤: 'ギ',
  艢: 'ロ',
  艨: 'モウ',
  艪: 'ロ',
  艫: 'ロ',
  舮: 'ロ',
  艱: 'カン',
  艷: 'エン',
  艸: 'ソウ',
  艾: 'カイ',
  芍: 'シャク',
  芒: 'ボウ',
  芫: 'ゲン',
  芟: 'セン',
  芻: 'スウ',
  芬: 'フン',
  苡: 'イ',
  苣: 'キョ',
  苟: 'コウ',
  苒: 'ゼン',
  苴: 'ソ',
  苳: 'トウ',
  苺: 'バイ',
  莓: 'バイ',
  范: 'ハン',
  苻: 'フ',
  苹: 'ヘイ',
  苞: 'ホウ',
  茆: 'エン',
  苜: 'モク',
  茉: 'マツ',
  苙: 'リュウ',
  茵: 'イン',
  茴: 'ウイ',
  茖: 'カク',
  茲: 'シ',
  茱: 'シュ',
  荀: 'ジュン',
  茹: 'ジョ',
  荐: 'セン',
  荅: 'トウ',
  茯: 'フク',
  茫: 'ボウ',
  茗: 'ミョウ',
  茘: 'レイ',
  莅: 'リ',
  莚: 'エン',
  莪: 'ガ',
  莟: 'ガン',
  莢: 'キョウ',
  莖: 'ケイ',
  茣: 'ゴ',
  莎: 'サ',
  莇: 'ジョ',
  莊: 'ソウ',
  荼: 'タ',
  莵: 'ト',
  荳: 'トウ',
  荵: 'ニン',
  莠: 'ユウ',
  莉: 'リ',
  莨: 'ロウ',
  菴: 'アン',
  萓: 'ギ',
  菫: 'キン',
  菎: 'コン',
  菽: 'シュク',
  萃: 'スイ',
  菘: 'スウ',
  萋: 'セイ',
  菁: 'セイ',
  菷: 'シュウ',
  萇: 'チョウ',
  菠: 'ハ',
  菲: 'ヒ',
  萍: 'ヘイ',
  萢: 'やち',
  萠: 'ホウ',
  莽: 'ボウ',
  萸: 'ユ',
  蔆: 'リョウ',
  菻: 'リン',
  葭: 'カ',
  萪: 'カ',
  萼: 'ガク',
  蕚: 'ガク',
  蒄: 'カン',
  葷: 'クン',
  葫: 'コ',
  蒭: 'シュウ',
  葮: 'ダン',
  蒂: 'テイ',
  葩: 'ハ',
  葆: 'ホウ',
  萬: 'マン',
  葯: 'ヤク',
  葹: 'シ',
  萵: 'ワ',
  蓊: 'オウ',
  葢: 'ガイ',
  蒹: 'ケン',
  蒿: 'コウ',
  蒟: 'コン',
  蓙: 'ザ',
  蓍: 'シ',
  蒻: 'ジャク',
  蓚: 'シュウ',
  蓐: 'ジョク',
  蓁: 'シン',
  蓆: 'セキ',
  蓖: 'ヒ',
  蒡: 'ボウ',
  蔡: 'サイ',
  蓿: 'シュク',
  蓴: 'シュン',
  蔗: 'シャ',
  蔘: 'シン',
  蔬: 'ソ',
  蔟: 'ゾク',
  蔕: 'テイ',
  蔔: 'フク',
  蓼: 'シン',
  蕀: 'キョク',
  蕣: 'シュン',
  蕘: 'ジョウ',
  蕈: 'ジン',
  蕁: 'ジン',
  蘂: 'ズイ',
  蕋: 'ズイ',
  蕕: 'ユウ',
  薀: 'ウン',
  薤: 'カイ',
  薈: 'カイ',
  薑: 'キョウ',
  薊: 'ケイ',
  薨: 'コウ',
  蕭: 'ショウ',
  薔: 'バ',
  薛: 'セツ',
  藪: 'ソウ',
  薇: 'ラ',
  薜: 'ヘイ',
  蕷: 'ヨ',
  蕾: 'ライ',
  薐: 'ロウ',
  藉: 'シャ',
  薺: 'セイ',
  藏: 'ゾウ',
  薹: 'タイ',
  藐: 'ビョウ',
  藕: 'グウ',
  藝: 'ゲイ',
  藥: 'ヤク',
  藜: 'レイ',
  藹: 'アイ',
  蘊: 'ウン',
  蘓: 'ソ',
  蘋: 'ヒン',
  藾: 'ライ',
  藺: 'リン',
  蘆: 'ロ',
  蘢: 'ロウ',
  蘚: 'セン',
  蘰: 'かつら',
  蘿: 'ラ',
  虍: 'コ',
  乕: 'コ',
  虔: 'ケン',
  號: 'コウ',
  虧: 'キ',
  虱: 'シツ',
  蚓: 'ズ',
  蚣: 'コウ',
  蚩: 'シ',
  蚪: 'ト',
  蚋: 'ゼイ',
  蚌: 'ボウ',
  蚶: 'カン',
  蚯: 'キュウ',
  蛄: 'コ',
  蛆: 'ソ',
  蚰: 'ユウ',
  蛉: 'レイ',
  蠣: 'レイ',
  蚫: 'ホウ',
  蛔: 'カイ',
  蛞: 'カツ',
  蛩: 'キョウ',
  蛬: 'キョウ',
  蛟: 'コウ',
  蛛: 'チュ',
  蛯: 'えび',
  蜒: 'エン',
  蜆: 'ケン',
  蜈: 'ゴ',
  蜀: 'ショク',
  蜃: 'シン',
  蛻: 'ゼイ',
  蜑: 'タン',
  蜉: 'フ',
  蜍: 'ショ',
  蛹: 'ヨウ',
  蜊: 'リ',
  蜴: 'エキ',
  蜿: 'エン',
  蜷: 'ケン',
  蜻: 'セイ',
  蜥: 'シャク',
  蜩: 'チョウ',
  蜚: 'ヒ',
  蝠: 'フク',
  蝟: 'イ',
  蝸: 'カ',
  蝌: 'カ',
  蝎: 'カツ',
  蝴: 'コ',
  蝗: 'コウ',
  蝨: 'シツ',
  蝮: 'フク',
  蝙: 'ヘン',
  蝓: 'ユ',
  蝣: 'ユウ',
  蝪: 'エキ',
  蠅: 'ヨウ',
  螢: 'ケイ',
  螟: 'メイ',
  螂: 'ロウ',
  螯: 'ゴウ',
  蟋: 'シツ',
  螽: 'シュウ',
  蟀: 'シュツ',
  蟐: 'ジョウ',
  雖: 'スイ',
  螫: 'セキ',
  蟄: 'チツ',
  螳: 'トウ',
  蟇: 'バ',
  蟆: 'バ',
  螻: 'ロウ',
  蟯: 'ギョウ',
  蟲: 'チュウ',
  蟠: 'ハン',
  蠏: 'カイ',
  蠍: 'カツ',
  蟾: 'セン',
  蟶: 'テイ',
  蟷: 'トウ',
  蠎: 'ボウ',
  蟒: 'ボウ',
  蠑: 'エイ',
  蠖: 'ワク',
  蠕: 'ゼン',
  蠢: 'シュン',
  蠡: 'レイ',
  蠱: 'コ',
  蠶: 'サン',
  蠹: 'ト',
  蠧: 'ト',
  蠻: 'バン',
  衄: 'ジク',
  衂: 'ジク',
  衒: 'ゲン',
  衙: 'ガ',
  衞: 'エイ',
  衢: 'ク',
  衫: 'サン',
  袁: 'エン',
  衾: 'キン',
  袞: 'コン',
  衵: 'ジツ',
  衽: 'ジン',
  袵: 'ジン',
  衲: 'ドウ',
  袂: 'ベイ',
  袗: 'シン',
  袒: 'タン',
  袮: 'デイ',
  袙: 'バツ',
  袢: 'ハン',
  袍: 'ホウ',
  袤: 'ボウ',
  袰: 'ほろ',
  袿: 'ケイ',
  袱: 'フク',
  裃: 'かみしも',
  裄: 'ゆき',
  裔: 'エイ',
  裘: 'キュウ',
  裙: 'クン',
  裝: 'ソウ',
  裹: 'ホウ',
  褂: 'ケイ',
  裼: 'セキ',
  裴: 'ハイ',
  裨: 'ヒ',
  裲: 'リョウ',
  褄: 'つま',
  褌: 'コン',
  褊: 'ヘン',
  褓: 'ホ',
  襃: 'ホウ',
  褞: 'ウン',
  褥: 'ジョク',
  褪: 'タイ',
  褫: 'チ',
  襁: 'キョウ',
  襄: 'ジョウ',
  褻: 'セツ',
  褶: 'ショウ',
  褸: 'ロ',
  襌: 'タン',
  褝: 'タン',
  襠: 'トウ',
  襞: 'ヘキ',
  襦: 'ジュ',
  襤: 'ラン',
  襭: 'ケツ',
  襪: 'ベツ',
  襯: 'シン',
  襴: 'ラン',
  襷: 'たすき',
  襾: 'ア',
  覃: 'タン',
  覈: 'カク',
  覊: 'キ',
  覓: 'ベキ',
  覘: 'テン',
  覡: 'ゲキ',
  覩: 'ト',
  覦: 'ユ',
  覬: 'キ',
  覯: 'コウ',
  覲: 'キン',
  覺: 'カク',
  覽: 'ラン',
  覿: 'テキ',
  觀: 'カン',
  觚: 'コ',
  觜: 'シ',
  觝: 'テイ',
  觧: 'カイ',
  觴: 'ショウ',
  觸: 'ショク',
  訃: 'フ',
  訖: 'キツ',
  訐: 'ケツ',
  訌: 'コウ',
  訛: 'カ',
  訝: 'ガ',
  訥: 'トツ',
  訶: 'カ',
  詁: 'コ',
  詛: 'ソ',
  詒: 'イ',
  詆: 'テイ',
  詈: 'リ',
  詼: 'カイ',
  詭: 'キ',
  詬: 'コウ',
  詢: 'ジュン',
  誅: 'チュウ',
  誂: 'チョウ',
  誄: 'ルイ',
  誨: 'カイ',
  誡: 'カイ',
  誑: 'キョウ',
  誥: 'コウ',
  誦: 'ショウ',
  誚: 'ショウ',
  誣: 'フ',
  諄: 'シュン',
  諍: 'ソウ',
  諂: 'テン',
  諚: 'ジョウ',
  諫: 'カン',
  諳: 'アン',
  諧: 'カイ',
  諤: 'ガク',
  諱: 'キ',
  謔: 'ギャク',
  諠: 'ケン',
  諢: 'コン',
  諷: 'フウ',
  諞: 'ヘン',
  諛: 'ユ',
  謌: 'カ',
  謇: 'ケン',
  謚: 'エキ',
  諡: 'エキ',
  謖: 'ショク',
  謐: 'ヒツ',
  謗: 'ボウ',
  謠: 'ヨウ',
  謳: 'オウ',
  鞫: 'キク',
  謦: 'ケイ',
  謫: 'タク',
  謾: 'マン',
  謨: 'ボ',
  譁: 'カ',
  譌: 'カ',
  譏: 'キ',
  譎: 'ケツ',
  證: 'ショウ',
  譖: 'シン',
  譛: 'シン',
  譚: 'タン',
  譫: 'トウ',
  譟: 'ソウ',
  譬: 'ヒ',
  譯: 'ヤク',
  譴: 'ケン',
  譽: 'ヨ',
  讀: 'ドク',
  讌: 'エン',
  讎: 'シュウ',
  讒: 'ザン',
  讓: 'ジョウ',
  讖: 'シン',
  讙: 'カン',
  讚: 'サン',
  谺: 'コ',
  豁: 'カツ',
  谿: 'ケイ',
  豈: 'カイ',
  豌: 'エン',
  豎: 'ジュ',
  豐: 'ホウ',
  豕: 'シ',
  豢: 'ケン',
  豬: 'チョ',
  豸: 'タイ',
  豺: 'サイ',
  貂: 'チョウ',
  貉: 'カク',
  貅: 'キュウ',
  貊: 'ハク',
  貍: 'リ',
  貎: 'ゲイ',
  貔: 'ヒ',
  豼: 'ヒ',
  貘: 'バク',
  戝: 'ザイ',
  貭: 'シツ',
  貪: 'タン',
  貽: 'イ',
  貲: 'シ',
  貳: 'ニ',
  貮: 'ニ',
  貶: 'ヘン',
  賈: 'コ',
  賁: 'フン',
  賤: 'セン',
  賣: 'バイ',
  賚: 'ライ',
  賽: 'サイ',
  賺: 'タン',
  賻: 'フ',
  贄: 'シ',
  贅: 'セイ',
  贊: 'サン',
  贇: 'イン',
  贏: 'エイ',
  贍: 'セン',
  贐: 'シン',
  齎: 'セイ',
  贓: 'ソウ',
  賍: 'ソウ',
  贔: 'ヒ',
  贖: 'ショク',
  赧: 'タン',
  赭: 'シャ',
  赱: 'ソウ',
  赳: 'キュウ',
  趁: 'チン',
  趙: 'チョウ',
  跂: 'キ',
  趾: 'シ',
  趺: 'シ',
  跏: 'カ',
  跚: 'サン',
  跖: 'セキ',
  跌: 'テツ',
  跛: 'ハ',
  跋: 'ハツ',
  跪: 'キ',
  跫: 'キョウ',
  跟: 'コン',
  跣: 'セン',
  跼: 'キョク',
  踈: 'ソ',
  踉: 'ロウ',
  跿: 'ト',
  踝: 'カ',
  踞: 'キョ',
  踐: 'セン',
  踟: 'チ',
  蹂: 'ジュウ',
  踵: 'ショウ',
  踰: 'ユ',
  踴: 'ヨウ',
  蹊: 'ケイ',
  蹇: 'ケン',
  蹉: 'サ',
  蹌: 'ソウ',
  蹐: 'セキ',
  蹈: 'トウ',
  蹙: 'シュク',
  蹤: 'ショウ',
  蹠: 'セキ',
  踪: 'ソウ',
  蹣: 'マン',
  蹕: 'ヒツ',
  蹶: 'ケツ',
  蹲: 'ソン',
  蹼: 'ボク',
  躁: 'ソウ',
  躇: 'チョ',
  躅: 'チョク',
  躄: 'ヘキ',
  躋: 'セイ',
  躊: 'チュウ',
  躓: 'チ',
  躑: 'テキ',
  躔: 'テン',
  躙: 'リン',
  躪: 'リン',
  躡: 'ジョウ',
  躬: 'キュウ',
  躰: 'タイ',
  軆: 'タイ',
  躱: 'タ',
  躾: 'しつ.ける',
  軅: 'やがて',
  軈: 'やがて',
  軋: 'アツ',
  軛: 'ヤク',
  軣: 'コウ',
  軼: 'イツ',
  軻: 'カ',
  軫: 'シン',
  軾: 'ショク',
  輊: 'チ',
  輅: 'ロ',
  輕: 'ケイ',
  輒: 'チョウ',
  輙: 'チョウ',
  輓: 'バン',
  輜: 'シ',
  輟: 'テツ',
  輛: 'リョウ',
  輌: 'リョウ',
  輦: 'レン',
  輳: 'ソウ',
  輻: 'フク',
  輹: 'フク',
  轅: 'エン',
  轂: 'コク',
  輾: 'テン',
  轌: 'そり',
  轉: 'テン',
  轆: 'ロク',
  轎: 'キョウ',
  轗: 'カン',
  轜: 'ジ',
  轢: 'レキ',
  轣: 'レキ',
  轤: 'ロ',
  辜: 'コ',
  辟: 'ヘキ',
  辣: 'ラツ',
  辭: 'ジ',
  辯: 'ベン',
  辷: 'すべ.る',
  迚: 'とても',
  迥: 'ケイ',
  迢: 'チョ',
  迪: 'テキ',
  迯: 'トウ',
  邇: 'ジ',
  迴: 'カイ',
  逅: 'コウ',
  迹: 'セキ',
  迺: 'ナイ',
  逑: 'キュウ',
  逕: 'ケイ',
  逡: 'シュン',
  逍: 'ショウ',
  逞: 'テイ',
  逖: 'テキ',
  逋: 'ホ',
  逧: 'さこ',
  逶: 'イ',
  逵: 'キ',
  逹: 'タツ',
  迸: 'ホウ',
  遏: 'アツ',
  遐: 'カ',
  遑: 'カン',
  遒: 'シュウ',
  逎: 'シュウ',
  遉: 'テイ',
  逾: 'ユ',
  遖: 'あっぱれ',
  遘: 'コウ',
  遞: 'テイ',
  遨: 'ゴウ',
  遯: 'トン',
  遶: 'ニョウ',
  隨: 'ズイ',
  遲: 'チ',
  邂: 'カイ',
  遽: 'キョ',
  邁: 'マイ',
  邀: 'ヨウ',
  邊: 'ヘン',
  邉: 'ヘン',
  邏: 'ラ',
  邨: 'ソン',
  邯: 'カン',
  邱: 'キュウ',
  邵: 'ショウ',
  郢: 'エイ',
  郤: 'ゲキ',
  扈: 'コ',
  郛: 'フ',
  鄂: 'ガク',
  鄒: 'スウ',
  鄙: 'ヒ',
  鄲: 'タン',
  鄰: 'リン',
  酊: 'テイ',
  酖: 'タン',
  酘: 'トウ',
  酣: 'カン',
  酥: 'ソ',
  酩: 'メイ',
  酳: 'シン',
  酲: 'テイ',
  醋: 'サク',
  醉: 'スイ',
  醂: 'ラン',
  醢: 'カイ',
  醫: 'イ',
  醯: 'ケイ',
  醪: 'ロウ',
  醵: 'キョ',
  醴: 'レイ',
  醺: 'クン',
  釀: 'ジョウ',
  釁: 'キン',
  釉: 'ユウ',
  釋: 'シャク',
  釐: 'リ',
  釖: 'トウ',
  釟: 'ハチ',
  釡: 'フ',
  釛: 'コク',
  釼: 'ケン',
  釵: 'サイ',
  釶: 'シ',
  鈞: 'キン',
  釿: 'キン',
  鈔: 'ショウ',
  鈬: 'タク',
  鈕: 'チュウ',
  鈑: 'バン',
  鉞: 'エツ',
  鉗: 'ケン',
  鉅: 'キョ',
  鉉: 'ケン',
  鉤: 'コウ',
  鉈: 'シャ',
  銕: 'テツ',
  鈿: 'デン',
  鉋: 'ホウ',
  鉐: 'セキ',
  銜: 'カン',
  銖: 'シュ',
  銓: 'セン',
  銛: 'セン',
  鉚: 'リュ',
  鋏: 'キョウ',
  銹: 'シュ',
  銷: 'ショウ',
  鋩: 'ボウ',
  錏: 'ア',
  鋺: 'エン',
  鍄: 'リョウ',
  錮: 'コ',
  錙: 'シ',
  錢: 'セン',
  錚: 'ソウ',
  錣: 'テツ',
  錺: 'かざり',
  錵: 'カ',
  錻: 'ブ',
  鍜: 'カ',
  鍠: 'コウ',
  鍼: 'シン',
  鍮: 'チュウ',
  鍖: 'チン',
  鎰: 'イツ',
  鎬: 'コウ',
  鎭: 'チン',
  鎔: 'ヨウ',
  鎹: 'かすがい',
  鏖: 'オウ',
  鏗: 'コウ',
  鏨: 'サン',
  鏥: 'シュウ',
  鏘: 'ソウ',
  鏃: 'ソク',
  鏝: 'マン',
  鏐: 'リュウ',
  鏈: 'レン',
  鏤: 'ル',
  鐚: 'ワ',
  鐔: 'タン',
  鐓: 'タイ',
  鐃: 'ドウ',
  鐇: 'ハン',
  鐐: 'リョウ',
  鐶: 'カン',
  鐫: 'セン',
  鐵: 'テツ',
  鐡: 'テツ',
  鐺: 'ト',
  鑁: 'バン',
  鑒: 'カン',
  鑄: 'チュウ',
  鑛: 'コウ',
  鑠: 'シャク',
  鑢: 'ロ',
  鑞: 'ロウ',
  鑪: 'ロ',
  鈩: 'ロ',
  鑰: 'ヤク',
  鑵: 'カン',
  鑷: 'セツ',
  鑽: 'サン',
  鑚: 'サン',
  鑼: 'ラ',
  鑾: 'ラン',
  钁: 'カク',
  鑿: 'サク',
  閂: 'サン',
  閇: 'ヘイ',
  閊: 'つか.える',
  閔: 'ビン',
  閖: 'ゆり',
  閘: 'コウ',
  閙: 'ドウ',
  閠: 'ケイ',
  閨: 'ケイ',
  閧: 'コウ',
  閭: 'リョ',
  閼: 'ア',
  閻: 'エン',
  閹: 'エン',
  閾: 'シキ',
  闊: 'カツ',
  濶: 'カツ',
  闃: 'ゲキ',
  闍: 'ト',
  闌: 'ラン',
  闕: 'ケツ',
  闔: 'コウ',
  闖: 'チン',
  關: 'カン',
  闡: 'セン',
  闥: 'タツ',
  闢: 'ヘキ',
  阡: 'セン',
  阨: 'ヤク',
  阮: 'ゲン',
  阯: 'シ',
  陂: 'ヒ',
  陌: 'ハク',
  陏: 'ダ',
  陋: 'ロウ',
  陷: 'カン',
  陜: 'キョウ',
  陞: 'ショウ',
  陝: 'セン',
  陟: 'チョク',
  陦: 'トウ',
  陲: 'スイ',
  陬: 'スウ',
  隍: 'コウ',
  隘: 'アイ',
  隕: 'イン',
  隗: 'カイ',
  險: 'ケン',
  隧: 'スイ',
  隱: 'イン',
  隲: 'チョク',
  隰: 'シツ',
  隴: 'ロウ',
  隶: 'タイ',
  隸: 'レイ',
  隹: 'サイ',
  雎: 'ショ',
  雋: 'シュン',
  雉: 'ジ',
  雍: 'ヨウ',
  襍: 'ザツ',
  雜: 'ザツ',
  霍: 'カク',
  雕: 'チョウ',
  雹: 'ハク',
  霄: 'ショウ',
  霆: 'テイ',
  霈: 'ハイ',
  霓: 'ゲイ',
  霎: 'ショウ',
  霑: 'テン',
  霏: 'ヒ',
  霖: 'リン',
  霙: 'エイ',
  霤: 'リュウ',
  霪: 'イン',
  霰: 'セン',
  霹: 'ヘキ',
  霽: 'セイ',
  霾: 'バイ',
  靄: 'アイ',
  靆: 'タイ',
  靈: 'レイ',
  靂: 'レキ',
  靉: 'アイ',
  靜: 'セイ',
  靠: 'コウ',
  靤: 'ホウ',
  靦: 'テン',
  靨: 'ヨウ',
  勒: 'ロク',
  靫: 'ジン',
  靱: 'ジン',
  靹: 'ケツ',
  鞅: 'オウ',
  靼: 'タン',
  鞁: 'ヒ',
  靺: 'マツ',
  鞆: 'とも',
  鞋: 'アイ',
  鞏: 'キョウ',
  鞐: 'こはぜ',
  鞜: 'トウ',
  鞨: 'カツ',
  鞦: 'シュウ',
  鞣: 'ジュウ',
  鞳: 'トウ',
  鞴: 'ヒ',
  韃: 'ダツ',
  韆: 'セン',
  韈: 'ベツ',
  韋: 'イ',
  韜: 'トウ',
  韭: 'キュウ',
  齏: 'セイ',
  韲: 'セイ',
  竟: 'キョウ',
  韶: 'ショウ',
  韵: 'イン',
  頏: 'コウ',
  頌: 'ショウ',
  頸: 'ケイ',
  頤: 'イ',
  頡: 'ケツ',
  頷: 'ガン',
  頽: 'タイ',
  顆: 'カ',
  顏: 'ガン',
  顋: 'サイ',
  顫: 'セン',
  顯: 'ケン',
  顰: 'ヒン',
  顱: 'ロ',
  顴: 'ケン',
  顳: 'ショウ',
  颪: 'おろし',
  颯: 'サツ',
  颱: 'タイ',
  颶: 'グ',
  飄: 'ヒョウ',
  飃: 'ヒョウ',
  飆: 'ヒョウ',
  飩: 'ドン',
  飫: 'ヨウ',
  餃: 'ギョウ',
  餉: 'ショウ',
  餒: 'ダイ',
  餔: 'ホ',
  餘: 'ヨ',
  餡: 'アン',
  餝: 'コウ',
  餞: 'セン',
  餤: 'タン',
  餠: 'ヘイ',
  餬: 'コ',
  餮: 'テツ',
  餽: 'キ',
  餾: 'リュウ',
  饂: 'ウン',
  饉: 'キン',
  饅: 'マン',
  饐: 'イ',
  饋: 'キ',
  饑: 'キ',
  饒: 'ジョウ',
  饌: 'セン',
  饕: 'トウ',
  馗: 'キ',
  馘: 'カク',
  馥: 'クク',
  馭: 'ギョ',
  馮: 'ヒョウ',
  馼: 'ブン',
  駟: 'シ',
  駛: 'シ',
  駝: 'ダ',
  駘: 'タイ',
  駑: 'ド',
  駭: 'ガイ',
  駮: 'カク',
  駱: 'ラク',
  駲: 'シュウ',
  駻: 'カン',
  駸: 'シン',
  騁: 'テイ',
  騏: 'キ',
  騅: 'スイ',
  駢: 'ヘン',
  騙: 'ヘン',
  騫: 'ケン',
  騷: 'ソウ',
  驅: 'ク',
  驂: 'サン',
  驀: 'バク',
  驃: 'ヒョウ',
  騾: 'ラ',
  驕: 'キョウ',
  驍: 'ギョウ',
  驛: 'エキ',
  驗: 'ケン',
  驟: 'シュウ',
  驢: 'ロ',
  驥: 'キ',
  驤: 'ジョウ',
  驩: 'カン',
  驫: 'ショウ',
  驪: 'リ',
  骭: 'カン',
  骰: 'トウ',
  骼: 'カク',
  髀: 'ヒ',
  髏: 'ロ',
  髑: 'ドク',
  髓: 'ズイ',
  體: 'タイ',
  髞: 'ソウ',
  髟: 'ヒュウ',
  髢: 'テイ',
  髣: 'ホウ',
  髦: 'ボウ',
  髯: 'ゼン',
  髫: 'チョウ',
  髮: 'ハツ',
  髴: 'フツ',
  髱: 'ホウ',
  髷: 'キョク',
  髻: 'ケイ',
  鬆: 'ショウ',
  鬘: 'バン',
  鬚: 'シュ',
  鬟: 'カン',
  鬢: 'ビン',
  鬣: 'リョウ',
  鬥: 'とうがまえ',
  鬧: 'トウ',
  鬨: 'コウ',
  鬩: 'ケキ',
  鬪: 'トウ',
  鬮: 'キュウ',
  鬯: 'チョウ',
  鬲: 'カク',
  魄: 'ハク',
  魃: 'ハツ',
  魏: 'ギ',
  魍: 'モウ',
  魎: 'リョウ',
  魑: 'チ',
  魘: 'エン',
  魴: 'ホウ',
  鮓: 'サ',
  鮃: 'ヒョウ',
  鮑: 'ホ',
  鮖: 'かじか',
  鮗: 'このしろ',
  鮟: 'アン',
  鮠: 'カイ',
  鮨: 'シ',
  鮴: 'めばる',
  鯀: 'コン',
  鯊: 'サ',
  鮹: 'ショウ',
  鯆: 'フ',
  鯏: 'あさり',
  鯑: 'かずのこ',
  鯒: 'こち',
  鯣: 'エキ',
  鯢: 'ゲイ',
  鯤: 'コン',
  鯔: 'シ',
  鯡: 'ヒ',
  鰺: 'ソウ',
  鯲: 'どじょう',
  鯱: 'しゃちほこ',
  鯰: 'ネン',
  鰕: 'カ',
  鰔: 'カン',
  鰉: 'コウ',
  鰓: 'サイ',
  鰌: 'シュ',
  鰆: 'シュン',
  鰈: 'チョウ',
  鰒: 'フク',
  鰊: 'レン',
  鰄: 'イ',
  鰮: 'オン',
  鰛: 'オン',
  鰥: 'カン',
  鰤: 'シ',
  鰡: 'リュウ',
  鰰: 'はたはた',
  鱇: 'コウ',
  鰲: 'ゴウ',
  鱆: 'ショウ',
  鰾: 'ヒョウ',
  鱚: 'きす',
  鱠: 'カイ',
  鱧: 'レイ',
  鱶: 'ショウ',
  鱸: 'ロ',
  鳧: 'フ',
  鳬: 'フ',
  鳰: 'にお',
  鴉: 'ア',
  鴈: 'ガン',
  鳫: 'ガン',
  鴃: 'ケイ',
  鴆: 'チン',
  鴪: 'イツ',
  鴦: 'オウ',
  鶯: 'オウ',
  鴣: 'コ',
  鴟: 'シ',
  鵄: 'シ',
  鴕: 'ダ',
  鴒: 'レイ',
  鵁: 'コウ',
  鴿: 'コウ',
  鴾: 'ボウ',
  鵆: 'ちどり',
  鵈: 'とび',
  鵝: 'ガ',
  鵞: 'ガ',
  鵤: 'いかる',
  鵑: 'ケン',
  鵐: 'ブ',
  鵙: 'ケキ',
  鵲: 'シャク',
  鶉: 'ジュン',
  鶇: 'トウ',
  鶫: 'トウ',
  鵯: 'ヒ',
  鵺: 'コウ',
  鶚: 'ガク',
  鶤: 'ウン',
  鶩: 'ボク',
  鶲: 'ひたき',
  鷄: 'ケイ',
  鷁: 'ゲキ',
  鶻: 'コツ',
  鶸: 'ジャク',
  鶺: 'セキ',
  鷆: 'テン',
  鷏: 'テン',
  鷂: 'ヨウ',
  鷙: 'ゴウ',
  鷓: 'シャ',
  鷸: 'イツ',
  鷦: 'ショウ',
  鷭: 'ハン',
  鷯: 'リョウ',
  鷽: 'カク',
  鸚: 'イン',
  鸛: 'カン',
  鸞: 'ラン',
  鹵: 'ロ',
  鹹: 'カン',
  鹽: 'エン',
  麁: 'ソ',
  麈: 'シュ',
  麋: 'ビ',
  麌: 'グ',
  麒: 'キ',
  麕: 'キン',
  麑: 'ゲイ',
  麝: 'ジャ',
  麥: 'バク',
  麩: 'フ',
  麸: 'フ',
  麪: 'メン',
  麭: 'ホウ',
  靡: 'ヒ',
  黌: 'コウ',
  黎: 'レイ',
  黏: 'ネン',
  黐: 'チ',
  黔: 'ケン',
  黜: 'チュツ',
  點: 'テン',
  黝: 'ユウ',
  黠: 'カツ',
  黥: 'ケイ',
  黨: 'トウ',
  黯: 'アン',
  黴: 'バイ',
  黶: 'アン',
  黷: 'トク',
  黹: 'チ',
  黻: 'フツ',
  黼: 'フ',
  黽: 'ボウ',
  鼇: 'ゴウ',
  鼈: 'ベツ',
  皷: 'コ',
  鼕: 'トウ',
  鼡: 'ソ',
  鼬: 'ユウ',
  鼾: 'カン',
  齊: 'セイ',
  齒: 'シ',
  齔: 'シン',
  齣: 'シャク',
  齟: 'ソ',
  齠: 'チョウ',
  齡: 'レイ',
  齦: 'ギン',
  齧: 'ゲツ',
  齬: 'ゴ',
  齪: 'サク',
  齷: 'アク',
  齲: 'ク',
  齶: 'ガク',
  龕: 'カン',
  龜: 'キ',
  龠: 'ヤク',
  堯: 'ギョウ',
  槇: 'テン',
  遙: 'ヨウ',
  瑤: 'ヨウ',
  凜: 'リン',
  熙: 'キ',
}
